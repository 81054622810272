import React from "react";
import { PriceModel } from "../Models/NewCarsModel";
import { ReactComponent as WalletIcon } from "../../../assets/IconWallet.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/IconCalendar.svg";
import { ReactComponent as RoadIcon } from "../../../assets/IconRoad.svg";
import InfoAboutPayment from "../../../Common/Components/InfoAboutPayment";
import LACOfferService from "../../LACOffers/Services/LACOffer.Service";
import LACOfferRepository from "../../LACOffers/Repositories/LACOffer.Repository";
import { observer } from "mobx-react-lite";

interface IProps {
  priceList: Array<PriceModel> | undefined;
}
const RentalPeriodForm: React.FC<IProps> = observer(({ priceList }) => {

  const handleDefaultPrice = (id: number) => {
    if (id === LACOfferRepository.mainPriceListId) {
      LACOfferService.set_mainPriceListId(undefined);
    } else {
      LACOfferService.set_mainPriceListId(id);
      LACOfferService.set_priceElementToPriceList(id)
    }
  }

  const handleMainPriceListIds = (id: number) => {
    if (LACOfferRepository.priceListIds) {
      if (LACOfferRepository?.priceListIds.includes(id) && LACOfferRepository?.mainPriceListId !== id) {
        LACOfferService.del_priceElementToPriceList(id)
      } else if (!LACOfferRepository?.priceListIds.includes(id)) {
        LACOfferService.set_priceElementToPriceList(id)
      }
    }
  }

  const checkIsDefaultPrice = (id: number) => {
    if (LACOfferRepository.mainPriceListId === id) { return '--default-price' }
    else { return '' }
  }

  return (
    <>
      {priceList?.map((element) => {
        const {
          limitKilometers,
          rentalLengthMonth,
          priceNet,
          id,
          limitKilometersExceedingPriceNet,
        } = element;
        return (
          <div key={id} className={`price-container${checkIsDefaultPrice(id)}`}>
            <div className="price-container__kilometers">
              <p className="price-container__paragraph">Kilometry</p>
              <RoadIcon className="price-container--svg" />
              {limitKilometers}
            </div>
            <div className="price-container__length-month">
              <p className="price-container__paragraph">Miesiące</p>
              <CalendarIcon className="price-container--svg" />
              {rentalLengthMonth}
            </div>
            <div className="price-container__exceeding-price">
              <p className="price-container__paragraph">koszt nadprzebiegu</p>
              <WalletIcon className="price-container--svg" />
              {limitKilometersExceedingPriceNet} zł/1km
            </div>
            <div className="price-container__payment">
              <InfoAboutPayment
                price={priceNet}
                textContent="+ Wybierz"
                textContentClick="Wybrano"
                externalFunction={() => handleMainPriceListIds(id)}
                id={id}
              />
            </div>
            <div
              onClick={() => handleDefaultPrice(id)}
              className={"price-container__default-value"}
            >
              {LACOfferRepository.mainPriceListId === id ? "Wybrana wartość domyślna" : "Wartość domyślna"}
            </div>
          </div>
        );
      })}
    </>
  );
});

export default RentalPeriodForm;
