import React, { FC, useCallback, useState } from 'react'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import { IExpandableContainer } from './ExpandableContainer.Model'
import styles from './ExpandableContainer.module.scss'
import { combineClasses } from '../../../../external.module/CMSComponents.Module/Services/CssServices'

const ExpandableContainer: FC<IExpandableContainer> = ({
  title,
  children,
  classes,
  className
}) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const onToggle = useCallback(() => {
    setIsExpanded((prev) => !prev)
  }, [])


  return (
    <div className={combineClasses(styles['root'], classes?.root, className)}>
      <div className={combineClasses(styles['header'], classes?.header)} onClick={onToggle}>
        {title}
        <KeyboardArrowUp className={combineClasses(styles[`expander${isExpanded ? '--expanded' : ''}`], classes?.expander)} />
      </div>
      <div className={combineClasses(styles[`content-wrapper${isExpanded ? '--expanded' : ''}`], classes?.contentWrapper)}>
        <div className={combineClasses(styles['content'], classes?.content)}>{children}</div>
      </div>
    </div>
  )
}

export default ExpandableContainer
