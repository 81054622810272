class AuthorizationRepository {
    private _token;
    
    public get token() {
        if(!this._token) {
            this._token = this.get_tokenFromSessionStorage()
        }
        return this._token;
    }

    public set token(value) {
        this.set_tokenToSessionStorage(value);
        this._token = value;
    }

    private set_tokenToSessionStorage(_token: string) {
        sessionStorage.setItem("token", _token);
    }

    private get_tokenFromSessionStorage() {
        return (sessionStorage.getItem("token") ? sessionStorage.getItem("token") : undefined);
    }
}

export default new AuthorizationRepository();