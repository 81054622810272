import React from "react"
import { Route, Switch } from "react-router-dom"
import MainTable from "../Views/MainTable/MainTable"
import CreateOfferView from "../Views/CreateOfferView"
import EditOfferView from "../Views/EditOfferView"
import ErboCarsSection from "../Components/Sections/ErboCars.Section"

const LacOfferRoute = () =>  (
  <Switch>
    <Route path="/usedCarsList/new/:id?" component={CreateOfferView}></Route>
    <Route path="/usedCarsList/edit/:id?" component={EditOfferView}></Route>
    <Route path="/usedCarsList/offer-cars/:id" component={ErboCarsSection} />
    <Route path="/" component={MainTable}></Route>
  </Switch>
)

export default LacOfferRoute
