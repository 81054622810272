import AuthorizationService from "../../Authorization.Module/Services/Authorization.Service";

//TODO: Potrzebny LANG do generowania templatki emailu dla backendu
export const formCustomerConnector = async (form: {customerEmail:string, customerToSendEmail:string}) => {
  try {
    return await fetch(process.env.REACT_APP_API_CUSTOMER + `customer/password/reset/init/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
        "Lang": "PL"
      },
      body: JSON.stringify(form),
    });
  } catch (err) {
    throw err;
  }
};


export const getUserDataConnector = async (idUser:URLSearchParams) => {
  try {
    return await fetch(process.env.REACT_APP_API_CUSTOMER + `customer/admin/details?${idUser}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
    });
  } catch (err) {
    throw err;
  }
};
