import React from "react";
import { Col, Row } from "react-bootstrap";
import { InputLabel, TextField } from "@material-ui/core";
import { LACOfferConst } from "../Const/LACOffer.Const";
import LACOfferService from "../../LACOffers/Services/LACOffer.Service";
import { observer } from "mobx-react-lite"

const BasicPriceVariableForm: React.FC = observer(() => {

    const roundNumber = (value: number) => {
        return value.toFixed(2)
    }

    const handleBasicPriceChange = (event) => {
        if (event.target.name === "basicPriceNet") {
            if (!event.target.value.match('^[0-9]*$')) { event.target.value = LACOfferConst.basicPriceConstant }
            const netPrice = event.target.value
            const grossPrice = roundNumber(netPrice * 1.23)
            LACOfferService.set_basicPrice(netPrice, grossPrice)
        }
        if (event.target.name === "basicPriceGross") {
            if (!event.target.value.match('^[0-9]*$')) { event.target.value = LACOfferConst.basicPriceConstant * 1.23 }
            const grossPrice = event.target.value
            const netPrice = roundNumber(grossPrice / 1.23)
            LACOfferService.set_basicPrice(netPrice, grossPrice)
        }
    }

    return (
        <Row style={{ display: 'flex' }}>
            <Col xs="6" style={{ marginBottom: "10px" }}>
                <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
                    Wartość stała ceny podstawowej netto
                </InputLabel>
                <TextField
                    name="basicPriceNet"
                    id="outlined-basic"
                    value={LACOfferService.get_lacBasicNetPrice()}
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(event) => { handleBasicPriceChange(event) }}
                />
            </Col>
            <Col xs="6">
                <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
                    Wartość stała ceny podstawowej brutto
                </InputLabel>
                <TextField
                    name="basicPriceGross"
                    id="outlined-basic"
                    value={LACOfferService.get_lacBasicGrossPrice()}
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(event) => { handleBasicPriceChange(event) }}
                    disabled
                />
            </Col>
        </Row>
    );
})

export default BasicPriceVariableForm;
