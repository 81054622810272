import { CarType } from "../Enums/CarType.Enum";
import { FeatureType } from "../Enums/FeatureType.Enum";
import { GearBoxType } from "../Enums/GearBox.Enum";
import { HookType } from "../Enums/HookType.Enum";
import { PeriodType } from "../Enums/PeriodType.Enum";
import { TruckCategoryTypes } from "../Enums/TruckCateogryType.Enum";
import { UsedCarsState } from "../Enums/UsedCarsState.Enum";

export default interface UsedCarsModel {
  id?: number | string;
  offerName?: string;
  offerNameDetails?: string;
  registrationNumber?: string;
  state?: UsedCarsState;
  offerType?: string;
  features?: Array<string> | Array<{ value: string; label: string }>;
  doors?: string;
  seats?: string;
  fuel?: string;
  gearbox?: string;
  distanceLimit?: number;
  nettoPrice?: string;
  bruttoPrice?: string;
  distanceExceeding?: string; //bo może być tutaj jakiś opis...
  hook?: string;
  addicionalEquipment?: any;
  packages?: {
    head: string;
    content: string;
  };
  carDescription?: string;
  images?: Array<any>;
  imageId?: number;
}

export interface CarInfo {
  doors: string | undefined;
  seats: string | undefined;
  fuel: undefined | string;
  gearbox: GearBoxType | undefined | string;
}

export interface CarFilter {
  carClassId?: number;
  categoryIds?: Array<any>;
  categoryId?: number;
  fuelId?: number;
  gearboxId?: number;
  seats?: number;
  luggage?: any;
  carTypes: Array<CarType> | Array<string>;
  truckCategoryTypes?: Array<TruckCategoryTypes>;
}

export interface Hook {
  id: string;
  type: HookType;
  images: [];
  code: string;
  value: string;
}

export interface Features {
  id: number;
  type: FeatureType;
  images: [];
  code: string;
  value: string;
}

export interface Price {
  EUR: string | undefined;
  PLN: string | undefined;
}

export class UsedCarsModelDTO {
  constructor() {
    this.jsonContent = {
      possibilities: ["ORDERCALL", "CALL"],
      carFilter: {
        carTypes: [CarType.CAR],
      },
      periodType: PeriodType.USEDCARS,
    };
  }
}

export interface IUsedCarsModelJsonContentDTO {
  id?: number; //Nadaway przez MID po POST
  code?: string;
  title?: string | undefined; // Car name
  subtitle?: string;
  imageId?: number;
  carInfo?: CarInfo;
  periodType: PeriodType;
  carFilter: CarFilter;
  distanceLimit?: number;
  extendable?: boolean;
  offerDataOrder?: [];
  offerType?: string;
  hooks?: Array<Hook> | Array<string> | Array<{ value: string }>;
  features?: Array<Features> | any;
  advantages?: [];
  price?: Price;
  overrunPrice?: Price;
  purchasePrice?: {};
  initialRentPrice?: {};
  deliveryPrice?: {};
  possibilities?: Array<any>;
  mainDatas?: [];
  usedCarElements?: {
    images?: Array<string>;
    registrationNumber?: string;
    carDescription?: string;
    addicionalEquipment?: Array<string>;
    packages?: {
      head: string | undefined;
      content: Array<string> | any;
    };
    url?: string;
  };
}

export interface UsedCarsModelDTO {
  status: UsedCarsState;
  jsonContent: IUsedCarsModelJsonContentDTO;
}
