import AuthorizationService from "../../Authorization.Module/Services/Authorization.Service";
import { showErrorSnackbar } from '../../../external.module/CMSComponents.Module/Snackbar'

export default class LACOffersConnector {
  public static async fetch_carsGroupByStatus(query: string) {
    try {
      const searchParams = query ? `&${query}` : ''
      const res = await fetch(
        process.env.REACT_APP_API_DATA + `car-group?max=100000${searchParams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AuthorizationService.get_token()}`,
          },
        }
      );
      const data = res.json();
      return data;
    } catch (err: unknown) {
      console.log(err);
      const message = err && typeof err == 'object' && 'message' in err
        ? err.message as string
        : 'Unknown Error'
      showErrorSnackbar(message)
    }
  }

  public static GET_carGroupDetailsById(_carGroupId: number) {
    return new Promise<any>((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL_LAC + `/car-group/${_carGroupId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  }

  public static async GET_SingleCarFromErbo(erboId: string) {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL_LAC + `/car-group/${erboId}/car`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AuthorizationService.get_token()}`,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  public static async POST_carGroupImage(
    _carGroupId: number,
    dataForm: FormData
  ) {
    const r = await fetch(
      process.env.REACT_APP_API_URL_LAC + `/car-group/${_carGroupId}/image`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
        body: dataForm,
      }
    );
    return await r;
  }

  public static async GET_carGroupMainImage(
    _url: string,
  ) {
    // const r = await fetch(
    //   process.env.REACT_APP_API_URL_LAC + `/car-group/${_carGroupId}/image/main.webp`,
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${AuthorizationService.get_token()}`,
    //     },
    //   }
    // );
    // return await r.blob();
    try {
      const r = await fetch(
        _url,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${AuthorizationService.get_token()}`,
          },
        }
      );
      return await r.blob();
    } catch (error) {
      console.log(error)
    }
    }
  //     try {
  //       const r = await fetch(
  //         process.env.REACT_APP_API_URL_LAC + `/car-group/${_carGroupId}/image/main.jpeg`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //           },
  //         }
  //       );
  //       return await r.blob();
  //     } catch (error) {
  //       try {
  //         const r = await fetch(
  //           process.env.REACT_APP_API_URL_LAC + `/car-group/${_carGroupId}/image/main.jpg`,
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //             },
  //           }
  //         );
  //         return await r.blob();
  //       } catch (error) {
  //         const r = await fetch(
  //           process.env.REACT_APP_API_URL_LAC + `/car-group/${_carGroupId}/image/main.png`,
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //             },
  //           }
  //         );
  //         return await r.blob();
  //       }
  //     }

  //   }
  // }

  public static async GET_carGroupAdditionalImage(
    _imageUrl: string
  ) {
    const r = await fetch(
      _imageUrl,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
      }
    );
    return await r.blob();
  }

  // public static async GET_carGroupAdditionalImageById(
  //   _imageId: string, _id: string
  // ) {
  //   // const r = await fetch(
  //   //   process.env.REACT_APP_API_URL_LAC + `/car-group/${_id}/image/${_imageId}.webp`,
  //   //   {
  //   //     method: "GET",
  //   //     headers: {
  //   //       Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //   //     },
  //   //   }
  //   // );
  //   // return await r.blob();
  //   try {
  //     const r = await fetch(
  //       process.env.REACT_APP_API_URL_LAC + `/car-group/${_id}/image/${_imageId}.webp`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //         },
  //       }
  //     );
  //     return await r.blob();
  //   } catch (error) {
  //     try {
  //       const r = await fetch(
  //         process.env.REACT_APP_API_URL_LAC + `/car-group/${_id}/image/${_imageId}.jpeg`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //           },
  //         }
  //       );
  //       return await r.blob();
  //     } catch (error) {
  //       try {
  //         const r = await fetch(
  //           process.env.REACT_APP_API_URL_LAC + `/car-group/${_id}/image/${_imageId}.jpg`,
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //             },
  //           }
  //         );
  //         return await r.blob();
  //       } catch (error) {
  //         const r = await fetch(
  //           process.env.REACT_APP_API_URL_LAC + `/car-group/${_id}/image/${_imageId}.png`,
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //             },
  //           }
  //         );
  //         return await r.blob();
  //       }
  //     }
  //   }

  // }

  public static async DEL_carGroupAdditionalImages(groupID:number, _listOfImagesIdsToDelete: { listOfImageIdsToDelete: number[]; }) {
    const r = await fetch(
      process.env.REACT_APP_API_URL_LAC + `/car-group/${groupID}/image`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
        body: JSON.stringify(_listOfImagesIdsToDelete),
      }
    );
    return await r;
  }

public static async DEL_carGroupMainImage(groupID:number, mainImageIdToDelete: number) {
  const r = await fetch(
    process.env.REACT_APP_API_URL_LAC + `/car-group/${groupID}/image`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
      body: JSON.stringify({"mainImageIdToDelete": mainImageIdToDelete}),
    }
  );
  return await r;
}
}
