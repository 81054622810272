
export default class NavigationService {

    private static changePolishCharakters(_str) {
        const polishCharakters = [
            ['ą', 'a'],
            ['ć', 'c'],
            ['ę', 'e'],
            ['ł', 'l'],
            ['ń', 'n'],
            ['ó', 'o'],
            ['ś', 's'],
            ['ź', 'z'],
            ['ż', 'z'],
        ]
        polishCharakters.forEach((e, i) => {
            if(_str.search(e[0]) >= 0) {
                console.log(_str, e[0], _str.search(e[0]), _str.replace(e[0], e[1]));
                _str = _str.replace(e[0], e[1])
            }
        })
        return _str;
    }

    private static removeSpecialCharacters(_str) {
        return _str.replace(/[!@#$%^/\\&*/]/g, "");
    }

    public static create_LinkStandard(_link: string): string {
        let link = this.changePolishCharakters(_link);
        link = this.removeSpecialCharacters(link);
        return link.normalize('NFD').replace(/ /g, "-").toLowerCase().trim();
    }

}