import React, { useCallback, useState } from "react";
import {
  _acceptedFormats,
  _errorsMessagesMap,
  HEIGHT,
  MAX_FILE_SIZE,
  WIDTH,
} from "./FormFiles.constans";
import {
  classGetter,
  createError,
  includes,
  prepareErrors,
} from "./FormFiles.helpers";
import { IError, IFormFiles } from "./FormFiles.models";
import FormFile from "./FormFile/FormFile";
import { ReactComponent as DownloadIcon } from "../../../assets/2download_doc.svg";
import { LACOfferServiceEnum } from "../../../Modules/LACOffers/Services/LACOffer.Service";

/**
 * Komponent renderuje input dla plików oraz listę plików załadowanych do komponentu.
 *
 * Klasy CSS-owe:
 * - <b><i>file__input-wrapper</i></b> - wrapper dla inputu
 * - <b><i>file__input-wrapper--hidden</i></b> - stan komponentu, gdy liczba plików załadowanych jest równa lub przekracza maksymalną dozwoloną liczbą plików
 * - <b><i>file__error</i></b> - informacja o błędzie
 * - <b><i>file__list-wrapper</i></b> - wrapper dla listy plików
 * - <b><i>file__list-wrapper--multiple</i></b> - stan komponentu, gdy parametr komponentu <i>multiple</i> jest włączony
 */

const FormFiles = ({
  files = [],
  multiple = false,
  acceptedFormats = _acceptedFormats,
  id,
  name,
  onFilesUpdate,
  description,
  thumbnailProps = { width: WIDTH, height: HEIGHT },
  deleteComponent,
  errorsMessagesMap = _errorsMessagesMap,
  overlayItemOnHover = false,
  downloadIcon = <DownloadIcon />,
}: IFormFiles): JSX.Element => {
  const [filesErrors, setFilesErrors] = useState<Array<IError>>([]);
  const [inputErrors, setInputErrors] = useState<IError | null>(null);
  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      // @ts-ignore
      const _files = multiple
        ? [...files, ...Array.from(event.target.files as FileList)]
        : Array.from(event.target.files as FileList);
      console.log(_files);
      if (_files.some(({ type }) => !includes(acceptedFormats, type))) {
        setInputErrors(createError(errorsMessagesMap.notAcceptedFormat));
        return;
      }

      const _filesErrors = prepareErrors(
        _files,
        ({ size }) => size > MAX_FILE_SIZE,
        errorsMessagesMap.fileIsTooBig
      );

      if (_filesErrors.some((error) => error.hasError)) {
        setFilesErrors(_filesErrors);
      }
      onFilesUpdate?.(_files, LACOfferServiceEnum.ADD);
    },
    [onFilesUpdate, files]
  );

  const deleteHandler = useCallback(
    (fileToRemove: File, event: LACOfferServiceEnum): void => {
      const newFiles = files.filter((file) => file.name !== fileToRemove.name);
      setFilesErrors((prevState) =>
        prevState.filter((error) => error.fileName !== fileToRemove.name)
      );
      onFilesUpdate?.(newFiles, fileToRemove, event);
    },
    [onFilesUpdate, files]
  );
  return (
    <>
      <div className={classGetter(["file__input-wrapper"])}>
        <label htmlFor={id}>
          {downloadIcon}
          <input
            id={id}
            name={name}
            type="file"
            onChange={changeHandler}
            accept={acceptedFormats.join(",")}
            multiple={multiple}
          />
          {description}
        </label>
      </div>
      {!!files.length && (
        <div
          className={classGetter([
            "file__list-wrapper",
            multiple && "file__list-wrapper--multiple",
          ])}
        >
          {files.map((file) => (
            <FormFile
              deleteComponent={deleteComponent}
              deleteHandler={deleteHandler}
              error={filesErrors.find((err) => err.fileName === file.name)}
              file={file}
              isSingle={!multiple}
              overlayOnHover={overlayItemOnHover}
              thumbnailProps={thumbnailProps}
              key={file.name}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default FormFiles;
