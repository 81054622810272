import FileConnector from "../Connectors/FileConnector";

export default class FileService {

    public static prepareImages(images: FileList) {

        let preparedImages: Array<FormData> = [];

        Array.from(images).forEach((image, i) => {
            const preparedImage: FormData = new FormData();
            preparedImage.append('file', image);
            preparedImage.append('name', image.name);
            preparedImage.append('mimeType', image.type);
            preparedImage.append('fileType', 'IMAGE');
            preparedImage.append('visibility', "PUBLIC");
            preparedImages.push(preparedImage)
        })

        return preparedImages;
    }

    public static saveImages(images: FileList) {

        return new Promise((resolve, reject) => {
            const preparedImages = FileService.prepareImages(images);
            let promises: Array<any> = [];

            promises = preparedImages.map((image, i) => {
                return FileConnector.upload(`${process.env.REACT_APP_API_CONTENT_URL}file`, image)
                    .catch(e=>{
                        alert(`Błąd przy wgrywaniu zdjęcia: ${image.get('name')}: \n${e.message}`);
                        reject(e.message)
                    });
            });
            Promise.allSettled(promises).then((r) => {
                resolve(r);
            })
        })

    }

    public static get_idsFormArray(_imagesArray) {
        const formImages: Array<any> = [];
        if (_imagesArray) {
            _imagesArray.forEach((imageId, i) => {
                imageId.status === "fulfilled" && formImages.push(imageId.value.file.id);
            })
        }
        return formImages.length !== 0 ? formImages : [];
    }
}