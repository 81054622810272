import React, { useState } from 'react'
import './Filter.scss'
import { combineClasses } from '../../../external.module/CMSComponents.Module/Services/CssServices'

interface IProps {
  onToggle?(newVale: boolean): void
  className?: { button?: string, filters?: string }
}

const FilterToggle: React.FC<IProps> = ({
  children,
  onToggle,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    let newVal = false
    setIsOpen((prev) => newVal = !prev)
    onToggle?.(newVal)
  }

  return (
    <>
      <button
        onClick={handleToggle}
        className={combineClasses(isOpen
          ? 'filter-car-section__filter-toggle__button--active'
          : 'filter-car-section__filter-toggle__button',
          className?.button
        )}
        data-test-id="filters-btn"
      >
        <span className="filter-car-section__filter-toggle--span">Filtry</span>
        <div
          className={
            isOpen ? 'filter-car-section__filter-toggle__strawberry-icon--active'
              : 'filter-car-section__filter-toggle__strawberry-icon'
          }
        />
      </button>
      {isOpen ? <div className={className?.filters}>{children}</div> : null}
    </>
  )
}

export default FilterToggle
