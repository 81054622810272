
export default class FileConnector{

    public static upload(service: string, body: FormData) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', service, true);
            xhr.setRequestHeader("Accept-Encoding", 'multipart/form-data');
            xhr.setRequestHeader("Lang", 'PL');
            xhr.onload = function(e) {
                if (this.status == 200) {
                    resolve(JSON.parse(this.response));        
                } else {
                    reject(JSON.parse(this.response));
                }
            };
            xhr.send(body);
        });
    }
}