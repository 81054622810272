
import { FiltersAction, IFilters } from '../Models/Filters.Model'

export const initState: IFilters = { page: 1, rowsPerPage: 25, search: undefined }
export const filtersReducer = (onUpdate: (state: IFilters) => Promise<void>) => (
  state: IFilters, action: FiltersAction | null) => {
  let newState
  const { type, payload } = action ?? {}
  switch (type) {
    case 'CHANGE_PAGE':
      newState = {
        ...state,
        page: payload
      }
      break
    case 'CHANGE_ROWS_PER_PAGE':
      newState = {
        ...state,
        rowsPerPage: payload,
        page: initState.page
      }
      break
    case 'CHANGE_STATUS':
      newState = {
        ...state,
        status: payload,
        page: initState.page,
        rowsPerPage: initState.rowsPerPage
      }
      break
    case 'NEXT_PAGE':
      newState = {
        ...state,
        page: state.page + 1
      }
      break
    case 'PREVIOUS_PAGE':
      newState = {
        ...state,
        page: state.page - 1
      }
      break
    case 'GLOBAL_SEARCH':
      newState = {
        ...state,
        search: payload || undefined,
        page: initState.page
      }
      break
    case 'RESET':
      newState = initState
      break
    default:
      newState = state
      break
  }

  (
    async () => onUpdate(newState)
  )()

  return newState
}
