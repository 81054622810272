import AuthorizationRepositories from "../Repositories/Authorization.Repositories";

export default class AuthorizationService {
  public static get_token() {
    return AuthorizationRepositories.token;
  }

  public static set_token(_token: string | null) {
    if (AuthorizationRepositories.token !== null) {
      AuthorizationRepositories.token = _token;
    }
  }
}
