import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import UsedCarsService from "../Services/UsedCarsService";
import ParametersGroupCarsSection from "../Components/Sections/ParametersGroupCars.Section";
import HeaderFormSection from "../Components/Sections/HeaderFormSection/Header.Section";
import AddicionalParametersOfferSection from "../Components/Sections/AddicionalParametersOffer.Section";
import RentalPeriodSection from "../Components/Sections/RentalPeriod.Section";
import LACOfferService from "../../LACOffers/Services/LACOffer.Service";
import SaveButton from "../../../Forms/FormsElements/SaveButton.Element";
import NewCarsModel from "../Models/NewCarsModel";
import LACOfferRepository from "../../LACOffers/Repositories/LACOffer.Repository";
import InputForms from "../Forms/InputForms";
import { useHistory } from "react-router-dom";
import { showWarningSnackbar } from "../../../external.module/CMSComponents.Module/Snackbar";

interface ILocationState {
  visibility?:boolean
}

const EditOfferView: React.FC = () => {
  let history = useHistory();

  const location = useLocation()
  const { visibility } = !location.state ? {visibility: false} : location.state as ILocationState;

  const [carData, setCarData] = useState<NewCarsModel[] | null>(null);
  const [visibilityData, setVisibilityData]= useState<string|undefined>(undefined)
  const [idsImagesToDelete, setIdsImagesToDelete] = useState<Array<number>>([]);
  const [delMainImage, setDelMainImage] = useState(false);

  const { id } = useParams<{ id: string }>();
  console.log(idsImagesToDelete);
  console.log(delMainImage);
  const getCarGroupData = async (id: number | string) => {
    try {
      const car: NewCarsModel[] = await UsedCarsService.get_carGroupData(id);
      setCarData(car);
      console.log(car);
      if (car[0].mainImageUrl && car[0].mainImageId) {
        await LACOfferService.get_carGroupMainImage(
          car[0].mainImageUrl,
          car[0].mainImageId
        );
      }
      if (car[0].notMainImagesUrls?.length) {
        await LACOfferService.get_carGroupAdditionalImages(
          car[0].notMainImagesUrls
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOffer = async (id: string | number) => {
    try {
      await LACOfferService.get_LACOffer(id);
    } catch (error) {
      console.log(error);
    }
  };

  const getErboIDAndOffer = async (id: string | number) => {
    try {
      const erboID = await LACOfferService.getLACOfferIDfromGroupID(id);
      const offerID = await erboID.offerId;
      await getOffer(offerID);
    } catch (error) {
      console.log(error);
    }
  };

  const edit__LACOffer = async () => {
    if (LACOfferRepository.mainPriceListId !== undefined) {
      if(visibilityData !== undefined){
         await LACOfferService.edit_Visibility(
          Number(id),
          visibilityData
        );
      }
      let additionalImages = true;
      if (LACOfferRepository.carGroupId) {
        if (
          LACOfferRepository.additionalImages &&
          carData &&
          idsImagesToDelete.length
        ) {
          additionalImages = await LACOfferService.del_LacOfferAdditionalImages(
            Number(id),
            idsImagesToDelete
          );
        }
        if (
          LACOfferRepository.mainImage &&
          carData &&
          carData[0].mainImageId &&
          delMainImage
        ) {
          await LACOfferService.del_LacOfferMainImage(
            Number(id),
            carData[0].mainImageId
          );
        }
        if (
          LACOfferRepository.mainImage &&
          LACOfferRepository.mainImage.length &&
          carData &&
          LACOfferRepository.mainImage[0].name !==
            `main${carData[0].mainImageId}.webp`
          // postuje jesli nazwa dodanego nie jest taka sama jak w repo
        ) {
          LACOfferService.post_LacOfferImage(
            LACOfferRepository.carGroupId,
            LACOfferRepository.mainImage,
            true
          );
        }
        if (
          LACOfferRepository.additionalImages &&
          carData &&
          additionalImages
        ) {
          LACOfferService.post_LacOfferImage(
            LACOfferRepository.carGroupId,
            LACOfferRepository.additionalImages.filter(
              (image) =>
                !carData[0]?.notMainImagesIds?.includes(
                  Number(image.name.split(".")[0])
                )
            ),
            false
          );
        }
      }

      if (additionalImages) {
        LACOfferService.edit_LACOffer(LACOfferRepository.OfferID);
        history.push("/usedCarsList");
        return;
      }
      showWarningSnackbar("Nie udało się dodać zdjeć");
    } else {
      showWarningSnackbar("Nie wybrano cennika domyślnego");
    }
  };

  const clearLACOfferRepository = () => {
    LACOfferRepository.carGroupId = undefined;
    LACOfferRepository.description = undefined;
    LACOfferRepository.typeText = undefined;
    LACOfferRepository.asteriskInformation = undefined;
    LACOfferRepository.priceListIds = undefined;
    LACOfferRepository.mainPriceListId = undefined;
    LACOfferRepository.modelEquipmentDetail = undefined;
    LACOfferRepository.additionalImages = undefined;
    LACOfferRepository.mainImage = undefined;
    LACOfferRepository.lacBasicPriceDto = undefined;
    LACOfferRepository.OfferID = undefined;
  };

  useEffect(() => {
    if (id) {
      clearLACOfferRepository();
      getCarGroupData(id);
      LACOfferService.set_carGroupId(Number(id));
      getErboIDAndOffer(id);
    }
  }, []);

  //dodałem e.revent default ponieważ po klikaniu wybierz w okresie najmu odswieza formularz
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div>
        <HeaderFormSection>Edycja samochody używane</HeaderFormSection>
        <div className="container">
          {!carData ? (
            <h2>Ładowanie</h2>
          ) : (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ParametersGroupCarsSection car={carData} visibility = {visibility} setVisible = {setVisibilityData}/>
              <RentalPeriodSection car={carData} />
              <AddicionalParametersOfferSection />
              <InputForms
                setAdditionalImagesIdsToDelete={setIdsImagesToDelete}
                setMainImageToDelete={setDelMainImage}
              />
              <SaveButton handleSave={edit__LACOffer} />
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditOfferView;
