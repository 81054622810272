import React from "react";
import { Link } from "react-router-dom";
import Header from "../../../../../Common/Components/Header.Component";
import { Col, Container } from "react-bootstrap";
import { ReactComponent as ArrowSvg } from "../../../../../assets/arrowLeft.svg";

const HeaderFormSection: React.FC = ({ children }) => {
  return (
    <>
      <Header text="Express - Oferta samochodu używanego" />
      <Container>
        <Col
          xs="12"
          style={{ marginBottom: "20px", marginTop: "20px", padding: "0" }}
        >
          <Link to="/usedCarsList" className="header-section__link">
            <ArrowSvg />
            Lista samochodów
          </Link>
        </Col>
        <h2 className="header-section__header">{children}</h2>
      </Container>
    </>
  );
};

export default HeaderFormSection;
