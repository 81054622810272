import React, {SetStateAction} from "react";
import { Col, Row } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  TextField,
  Select,
} from "@material-ui/core";
import ReactSelect from "react-select";
import {
  getFormatArrayFeaturesForReactSelect,
  getFormatArrayIdCarsForReactSelect,
  getFormatArrayLocationIdForReactSelect,
} from "../Mappers/UsedCars.Mapper";
import { GearBoxType } from "../Enums/GearBox.Enum";
import { FuelType } from "../Enums/FuelType.Enum";
import PlateComponent from "../../../Common/Components/Plate.Component";
import { CarStatusVisibilityEnum, CarStatusVisibilityMap, CarsStatusEnum } from "../Enums/CarStatus.Enum";

interface IProps {
  car: any;
  visibility?: boolean;
  setVisible?: React.Dispatch<SetStateAction<string|undefined>>
}
const ParametersGroupCars: React.FC<IProps> = ({ car, visibility = true, setVisible }) => {

const getByValue = (map, status) => {
  for (let [key, value] of map.entries()) {
    if (value === status)
      return key;
  }
}

  return (
    <>
    {(car[0].cars[0]?.status &&
      (car[0].cars[0].status === CarsStatusEnum.PUBLISHED 
    || car[0].cars[0].status === CarsStatusEnum.UNPUBLISHED)
    ) 
     && (
        <PlateComponent title="Status oferty" classess="plate-container__no-margin">
      <Row>
        <Col xs="6" style={{ marginBottom: "10px" }}>
        <FormControl variant="outlined" fullWidth>
            <div style={{ fontSize: "14px", fontWeight: 100 }}>
              Status
            </div>
            <MaterialSelect
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={car[0].cars[0].status}
              variant="outlined"
              disabled
            >
              {Object.keys(CarsStatusEnum).map((e, i) => {
                return (
                  <MenuItem key={i} value={e}>
                    {CarsStatusEnum[e]}
                  </MenuItem>
                );
              })}
            </MaterialSelect>
          </FormControl>
        </Col>
        <Col xs="6" style={{ marginBottom: "10px" }}>
        <FormControl variant="outlined" fullWidth>
            <div style={{ fontSize: "14px", fontWeight: 100 }}>
              Widoczność oferty na stronie WWW
            </div>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={CarStatusVisibilityMap.get(visibility)}
              /*@ts-ignore*/
              onChange={(e, child) => setVisible && setVisible(child?.props!.children[0])}
              variant="outlined"
              disabled ={car[0].cars[0].status === CarsStatusEnum.UNPUBLISHED}
            >
              {[...CarStatusVisibilityMap.entries()].map((value) => {
                  return (<MenuItem 
                  key={`${value[0]}`} 
                  value={value[1]}
                  >
                    {value}
                  </MenuItem>)
                ;
              })}
            </Select>
          </FormControl>
        </Col>
      </Row>
      </PlateComponent>
      )
    }
      
      <PlateComponent title="Parametry grupy samochodów">
        <Row>
        <Col xs="8" style={{ marginBottom: "10px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Nazwa samochodu*
          </InputLabel>
          <TextField
            name="nazwa samochodu"
            id="outlined-basic"
            defaultValue={car[0].name}
            variant="outlined"
            required
            fullWidth
            disabled
          />
        </Col>
        <Col xs="4" style={{ marginBottom: "10px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Klasa samochodu
          </InputLabel>
          <TextField
            name="klasa samochodu"
            id="outlined-basic"
            defaultValue="A"
            variant="outlined"
            required
            fullWidth
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6" style={{ marginBottom: "10px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Nazwa samochodu - szczegóły
          </InputLabel>
          <TextField
            id="outlined-basic"
            defaultValue={car[0].detailedName}
            variant="outlined"
            placeholder="1,5 TDI 150KM"
            fullWidth
            disabled
          />
        </Col>
        <Col xs="6" style={{ marginBottom: "10px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Numer Rejestracyjny*
          </InputLabel>
          <TextField
            id="outlined-basic"
            required
            variant="outlined"
            fullWidth
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col xs="3" style={{ marginBottom: "10px" }}>
          <FormControl variant="outlined" fullWidth>
            <div style={{ fontSize: "14px", fontWeight: 100 }}>
              Skrzynia biegów
            </div>
            <MaterialSelect
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={car[0].gearbox}
              disabled
            >
              {Object.keys(GearBoxType).map((e, i) => {
                return (
                  <MenuItem key={i} value={e}>
                    {GearBoxType[e]}
                  </MenuItem>
                );
              })}
            </MaterialSelect>
          </FormControl>
        </Col>
        <Col xs="3" style={{ marginBottom: "10px" }}>
          <FormControl variant="outlined" fullWidth>
            <div style={{ fontSize: "14px", fontWeight: 100 }}>Paliwo</div>
            <MaterialSelect
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={car[0].fuelType}
              disabled
            >
              {Object.keys(FuelType).map((e, i) => {
                return (
                  <MenuItem key={i} value={FuelType[e]}>
                    {FuelType[e]}
                  </MenuItem>
                );
              })}
            </MaterialSelect>
          </FormControl>
        </Col>
        <Col xs="3" style={{ marginBottom: "10px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Ilość drzwi
          </InputLabel>
          <TextField
            id="outlined-basic"
            defaultValue={car[0].doorsNumber}
            variant="outlined"
            fullWidth
            disabled
          />
        </Col>
        <Col xs="3" style={{ marginBottom: "10px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Ilość siedzeń
          </InputLabel>
          <TextField
            id="outlined-basic"
            defaultValue={car[0].seatsNumber}
            variant="outlined"
            fullWidth
            disabled
          />
        </Col>
      </Row>
      <Col xs="12" style={{ marginBottom: "10px", padding: "0px" }}>
        <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
          Id samochodów
        </InputLabel>
        <ReactSelect
          id="idsamochodow"
          name="id"
          placeholder=""
          options={getFormatArrayLocationIdForReactSelect(car)}
          defaultValue={getFormatArrayLocationIdForReactSelect(car)}
          isMulti
          isDisabled={true}
        />
      </Col>
      <Col xs="12" style={{ marginBottom: "10px", padding: "0px" }}>
        <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
          Id samochodów
        </InputLabel>
        <ReactSelect
          id="idsamochodow"
          name="id"
          placeholder=""
          options={getFormatArrayIdCarsForReactSelect(car)}
          defaultValue={getFormatArrayIdCarsForReactSelect(car)}
          isMulti
          isDisabled={true}
        />
      </Col>
      <Col xs="12" style={{ marginBottom: "10px", padding: "0px" }}>
        <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
          Wyposażenie zwykłe
        </InputLabel>
        <ReactSelect
          id="wyposażenie zwykłe"
          name="wyposażenie zwykłe"
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder=""
          options={getFormatArrayFeaturesForReactSelect(car)}
          defaultValue={getFormatArrayFeaturesForReactSelect(car)}
          isMulti
          isDisabled={true}
        />
      </Col>
    </PlateComponent>

      
    </>
  );
};

export default ParametersGroupCars;
