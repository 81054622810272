import React, { useEffect, useState } from "react";
import LACOfferRepository from "../../Modules/LACOffers/Repositories/LACOffer.Repository";
import { observer } from "mobx-react-lite";
interface IProps {
  gross?: string;
  price: string | number;
  textContent: string;
  textContentClick: string;
  externalFunction?: Function;
  id: number;
}

const InfoAboutPayment: React.FC<IProps> = observer(({
  gross,
  price,
  textContent,
  textContentClick,
  externalFunction,
  id
}) => (
  <>
    <div className="info-about-payment-container_price">
      {price} zł
      <p className="info-about-payment-container_price-month">
        /Netto miesięcznie
      </p>
    </div>
    <div className="info-about-payment-container_gross">{gross}</div>
    <button
      onClick={() => externalFunction?.()}
      className={
        LACOfferRepository.priceListIds?.includes(id)
          ? "info-about-payment-container_button--active"
          : "info-about-payment-container_button"
      }
    >
      {LACOfferRepository.priceListIds?.includes(id) ? textContentClick : textContent}
    </button>
  </>
));

export default InfoAboutPayment;
