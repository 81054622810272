import React from "react";
import { InputLabel, TextField } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import LACOfferService from "../../LACOffers/Services/LACOffer.Service";
import { observer } from "mobx-react-lite"

const AdditionalParametersOfferForm: React.FC = observer(() => {

  return (
    <>
      <Row>
        <Col xs="12" style={{ marginBottom: "25px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Informacje o ofercie
          </InputLabel>
          <TextField
            id="outlined-basic"
            name="asteriskInformation"
            value={LACOfferService.get_lacOfferAsteriskInformation()}
            multiline
            variant="outlined"
            placeholder="Lorem ipsum dolor sit am"
            fullWidth
            onChange={(e: any) => { LACOfferService.set_lacOfferAsteriskInformation(e.target.value) }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" style={{ marginBottom: "25px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Dodatkowy opis
          </InputLabel>
          <TextField
            id="outlined-basic"
            name="description"
            value={LACOfferService.get_lacOfferDescription()}
            multiline
            variant="outlined"
            placeholder="Lorem ipsum dolor sit am"
            fullWidth
            onChange={(e: any) => {
              LACOfferService.set_lacOfferDescription(e.currentTarget.value)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" style={{ marginBottom: "25px" }}>
          <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
            Tekst promocji
          </InputLabel>
          <TextField
            id="outlined-basic"
            name="typeText"
            value={LACOfferService.get_lacOfferTypeText()}
            multiline
            variant="outlined"
            placeholder="Lorem ipsum dolor sit am"
            fullWidth
            onChange={(e: any) => { LACOfferService.set_lacOfferTypeText(e.currentTarget.value) }}
          />
        </Col>
      </Row>
    </>
  );
});

export default AdditionalParametersOfferForm;
