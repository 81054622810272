import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LACOfferService from "../../../LACOffers/Services/LACOffer.Service";
import RmsIcon from "../../../../assets/rms.svg";
import HeaderTable from "../../../../Common/Components/HeaderTable";
import LinkComponent from "../../../../Common/Components/Link.Component";
import HeaderFormSection from "./HeaderFormSection/Header.Section";

interface IUseLocation {
  name: string;
}
const dataHeader = [
  "ErboId",
  "RmsId",
  "Lokalizacja",
  "Status",
  "Status RMS",
  "Actions",
];
const ErboCarsSection = () => {
  const name = useLocation<IUseLocation>().state;
  let { id } = useParams<{ id: string }>();
  const [car, setCar] = useState<any>([]);
  const fetchData = async () => {
    const response = await LACOfferService.getErboCar(id);
    response.cars && setCar(response.cars);
  };
  useEffect(() => {
    id && fetchData();
  }, []);
  return (
    <>
      <HeaderFormSection>{`Samochody grupy ${id} - ${name}`}</HeaderFormSection>
      <div className="table-container">
        <HeaderTable titles={dataHeader} classess={'table-header--other'} />
        {car.length > 0 &&
          car.map((car) => {
            return (
              <div
                key={car.id}
                className="car-container"
              >
                <div className="car-container_id">{car.carGroupId}</div>
                <div className="car-container_id">{car.carGroupRmsId}</div>
                <div className="car-container_name">
                  <h5 className="car-container_name-title">
                    {car.carLocationName}
                  </h5>
                </div>
                <div
                  className={
                    car.status === "PUBLISHED"
                      ? "car-container_state--active"
                      : "car-container_state"
                  }
                >
                  {car.status}
                </div>
                <div
                  className={
                    car.rmsActive
                      ? "car-container_state--active"
                      : "car-container_state"
                  }
                >
                  {car.rmsActive.toString()}
                </div>
                <div className="car-container_icons">
                  <LinkComponent
                    path={`${process.env.REACT_APP_RMS_WWW_ADDRESS}/OfertaLACDTO/Edit/${car.carGroupRmsId}`}
                    target
                    icon={RmsIcon}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ErboCarsSection;
