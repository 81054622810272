export enum CarsStatusEnum {
  UNPUBLISHED = "UNPUBLISHED",
  PUBLISHED = "PUBLISHED",
  PUBLISHED_NOT_ACTIVE = "PUBLISHED_NOT_ACTIVE",
  RESERVED = "RESERVED",
  HANDED_OVER = "HANDED_OVER",
  IN_MAGAZINE = "IN_MAGAZINE",
  ALL = "ALL",
}

export enum CarStatusVisibilityEnum {
  VISIBILITY_TRUE = "Widoczna",
  VISISIBILITY_FALSE = "Ukryta",
}

export enum CarVisibility {
  HIDDEN = "HIDDEN",
  VISIBLE = "VISIBLE",
  ALL = "ALL"
}

export const CarStatusVisibilityMap = new Map([
  [true, "Widoczna"],
  [false, "Ukryta"]
]);

