import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UsedCarsService from "../Services/UsedCarsService";
import ParametersGroupCarsSection from "../Components/Sections/ParametersGroupCars.Section";
import HeaderFormSection from "../Components/Sections/HeaderFormSection/Header.Section";
import AddicionalParametersOfferSection from "../Components/Sections/AddicionalParametersOffer.Section";
import RentalPeriodSection from "../Components/Sections/RentalPeriod.Section";
import LACOfferService from "../../LACOffers/Services/LACOffer.Service";
import SaveButton from "../../../Forms/FormsElements/SaveButton.Element";
import LACOfferRepository from "../../LACOffers/Repositories/LACOffer.Repository";
import NewCarsModel from "../Models/NewCarsModel";
import InputForms from "../Forms/InputForms";
import { showWarningSnackbar } from "../../../external.module/CMSComponents.Module/Snackbar";
import { useHistory } from 'react-router-dom'

const CreateOfferView: React.FC = () => {
  let history = useHistory()

  const [carData, setCarData] = useState<NewCarsModel[] | null>(null);
  const [idsImagesToDelete, setIdsImagesToDelete] = useState<Array<number>>([])
  const [delMainImage, setDelMainImage] = useState(false)

  const { id } = useParams<{ id: string }>();

  const getCarGroupData = async (id: number | string) => {
    try {
      const car: NewCarsModel[] = await UsedCarsService.get_carGroupData(id);
      LACOfferService.set_allPriceListActive(car)
      setCarData(car);
      if (car[0].mainImageUrl && car[0].mainImageId) {
        await LACOfferService.get_carGroupMainImage(car[0].mainImageUrl, car[0].mainImageId);
      }
      if (car[0].notMainImagesUrls?.length) {
        await LACOfferService.get_carGroupAdditionalImages(car[0].notMainImagesUrls)
      }
      // if (car[0].notMainImagesIds?.length) {
      //   await LACOfferService.get_carGroupAdditionalImagesByIds(car[0].notMainImagesIds, id)
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const post__LACOffer = () => {
    if (LACOfferRepository.mainPriceListId !== undefined) {
      if (LACOfferRepository.carGroupId) {
        if (LACOfferRepository.mainImage && LACOfferRepository.mainImage.length && LACOfferRepository.mainImage[0].name) {
          LACOfferService.post_LacOfferImage(
            LACOfferRepository.carGroupId,
            LACOfferRepository.mainImage,
            true
          );
        }
        if (LACOfferRepository.additionalImages && carData) {
          LACOfferService.post_LacOfferImage(
            LACOfferRepository.carGroupId,
            LACOfferRepository.additionalImages.filter(image => !carData[0]?.notMainImagesIds?.includes(Number(image.name.split('.')[0]))),
            false
          );
        }
      }

      if (LACOfferRepository.additionalImages && carData && idsImagesToDelete.length) {
        LACOfferService.del_LacOfferAdditionalImages(Number(id), idsImagesToDelete)
      }

      if (LACOfferRepository.mainImage && carData && carData[0].mainImageId && delMainImage) {
        LACOfferService.del_LacOfferMainImage(Number(id), carData[0].mainImageId);
      }

      LACOfferService.post_LACOffer()
      history.push("/usedCarsList")
    }
    else {
      showWarningSnackbar('Nie wybrano cennika domyślnego')
    }
  };

  const setStartValueLACOfferRepository = () => {
    LACOfferRepository.carGroupId = undefined;
    LACOfferRepository.description = undefined;
    LACOfferRepository.typeText = undefined;
    LACOfferRepository.asteriskInformation = undefined;
    LACOfferRepository.priceListIds = undefined;
    LACOfferRepository.mainPriceListId = undefined;
    LACOfferRepository.modelEquipmentDetail = undefined;
    LACOfferRepository.additionalImages = undefined;
    LACOfferRepository.mainImage = undefined;
    LACOfferRepository.OfferID = undefined;
    LACOfferService.set_basicPrice(100, 123)
  }

  useEffect(() => {
    if (id) {
      setStartValueLACOfferRepository()
      getCarGroupData(id);
      LACOfferService.set_carGroupId(Number(id));
    }
  }, []);
  //dodałem e.revent default ponieważ po klikaniu wybierz w okresie najmu odswieza formularz
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div>
        <HeaderFormSection>Samochody używane</HeaderFormSection>
        <div className="container">
          {!carData ? (
            <h2>Ładowanie</h2>
          ) : (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ParametersGroupCarsSection car={carData} />
              <RentalPeriodSection car={carData} />
              <AddicionalParametersOfferSection />
              <InputForms
                setAdditionalImagesIdsToDelete={setIdsImagesToDelete}
                setMainImageToDelete={setDelMainImage} />
              <SaveButton handleSave={post__LACOffer} />
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateOfferView;

