import React, { ReactNode, useState } from 'react'
import { DropdownButton, Dropdown } from "react-bootstrap";

interface IProps {
  handleChange?(option: number): void;
  total: number
  renderTitle(count: number): ReactNode
  rowsPerPage: number
}

const ButtonSelect: React.FC<IProps> = ({
  handleChange,
  total,
  renderTitle,
  rowsPerPage = 0
}) => {
  const displayCarsPerPage = [
    {
      value: 5,
    },
    {
      value: 10,
    },
    {
      value: 15,
    },
    {
      value: 20,
    },
    {
      value: 25,
    },
    {
      value: total,
    },
  ];

  return (
    <DropdownButton
      id="dropdown-item-button"
      variant="outline-secondary"
      title={renderTitle( rowsPerPage)}
    >
      {displayCarsPerPage.map((element) => (
        element.value <= total
          ? (
            <Dropdown.Item
              key={element.value}
              onClick={() => { handleChange?.(element.value) }}
              as="button"
            >
              {renderTitle(element.value)}
            </Dropdown.Item>
          )
          : null
      ))}
    </DropdownButton>
  );
};

export default ButtonSelect;
