import { useEffect } from 'react'

type Listener<Data> = (event: CustomEvent<Data>) => void
type TriggerEvent<Data> = (data?: Data | undefined) => void

/**
 * Funkcja przygotowuje Event do wywołania.
 * @param eventName - nazwa Eventu, który ma zostać wywołane
 * @return {function} Funkcja wywołująca Event wraz z podanymi danymi
 */
export const prepareTriggerEvent =
  <Data>(eventName: string): TriggerEvent<Data> =>
    (data) => {
      const event = new CustomEvent<Data>(eventName, { detail: data })
      window.dispatchEvent(event)
    }

/**
 * Sprawdza, czy podany Event jest zdarzeniem typu CustomEvent i gdy jest nadaje mu typ CustomEvent
 * @param event - obiekt Eventu
 */
const isCustomEvent = <Data>(event: Event): event is CustomEvent<Data> =>
  'detail' in event

/**
 * Przygotowuje funkcje, która zostanie wpięta w hooku na useEffect.
 * @param eventName - nazwa eventu do nasłuchiwania
 * @param listener - dodatkowy słuchacz, który zostanie podpięty
 */
const prepareEffect =
  <Data>(eventName: string, listener?: Listener<Data>) =>
    () => {
      if (!listener) {
        return
      }

      const eventListener = (event: Event) => {
        if (!isCustomEvent<Data>(event)) {
          console.error(
            'Event is not a Custom Event, please check your implementation.'
          )
          return
        }

        listener(event)
      }

      window.addEventListener(eventName, eventListener)

      return () => {
        window.removeEventListener(eventName, eventListener)
      }
    }

/**
 * Hook tworzy słuchacza, który będzie nasłuchiwał eventów o podanej nazwie. Tworzy go tylko raz podczas pierwszego
 * renderu. Jeśli potrzebujesz by słuchacz był aktualizowany po każdej zmianie funkcji nasłuchującej użyj
 * {@link useCustomEvent}.
 * @param eventName - nazwa eventu do nasłuchiwania
 * @param listener - dodatkowy słuchacz, który zostanie podpięty
 * @return {function} Funkcja wywołująca Event wraz z podanymi danymi
 */
export const useCustomEventOnce = <Data = undefined>(
  eventName: string,
  listener?: Listener<Data>
): TriggerEvent<Data> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(prepareEffect<Data>(eventName, listener), [])

  return prepareTriggerEvent<Data>(eventName)
}

/**
 * Hook tworzy słuchacza, który będzie nasłuchiwał eventów o podanej nazwie. Słuchacz będzie się aktualizował
 * za każdym razem, gdy zmieni się odwołanie do funkcji nasłuchującej. Jeśli słuchacz nie potrzebuje się odświeżać
 * użyj proszę {@link useCustomEventOnce}.
 * @param eventName - nazwa eventu do nasłuchiwania
 * @param listener - dodatkowy słuchacz, który zostanie podpięty
 * @return {function} Funkcja wywołująca Event wraz z podanymi danymi
 */
export const useCustomEvent = <Data = undefined>(
  eventName: string,
  listener?: Listener<Data>
): TriggerEvent<Data> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(prepareEffect<Data>(eventName, listener), [listener])

  return prepareTriggerEvent<Data>(eventName)
}

export default useCustomEvent
