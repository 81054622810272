import NavigationService from "../../../Common/Services/NavigationService";
import { UsedCarsState } from "../Enums/UsedCarsState.Enum";
import UsedCarsModel, { UsedCarsModelDTO } from "../Models/UsedCars.Model";
import FileService from "../Services/FileService";
import UsedCarsService from "../Services/UsedCarsService";
import NewCarsModel from "../Models/NewCarsModel";
import { ITableCarsModel } from "../Models/TableCars.Model";
import ICarLac from "../Models/CarLac.Model";

export default class UsedCarsMapper {
  private static convert_featuresToDTO(_featuresArray) {
    let featuresArray: Array<any> = [];
    if (_featuresArray) {
      _featuresArray.forEach((e, i) => {
        featuresArray.push({
          value: e.label,
          id: i,
          images: [],
        });
      });
    }
    return featuresArray;
  }

  private static convert_addicionalEquipmentToDTO(_addicionalEquipment) {
    const addicionalEquipmentArray: string[] = [];
    if (_addicionalEquipment) {
      _addicionalEquipment.map((element: { value: string; label: string }) => {
        addicionalEquipmentArray.push(element.value);
      });
    }
    return addicionalEquipmentArray;
  }

  private static async convert_imageToDTO(_imagesArray) {
    const response = await FileService.saveImages(_imagesArray);
    const formImages = FileService.get_idsFormArray(response);
    return formImages;
  }

  private static async get_firstPhotoFromImage(_imagesArray) {
    const imageId = await this.convert_imageToDTO(_imagesArray);
    return imageId[0];
  }

  public static async map_toDTO(_form, _prevForm?: UsedCarsModel) {
    const form = new UsedCarsModelDTO();
    form.status = _form.offerStatus.current?.value
      ? _form.offerStatus.current.value
      : UsedCarsState.ACTIVE;
    form.jsonContent.title = _form.offerName.current?.value
      ? _form.offerName.current.value.trim()
      : "";
    form.jsonContent.subtitle =
      _form.offerNameDetails.current?.value &&
      _form.offerNameDetails.current.value.trim();
    form.jsonContent.imageId =
      _form.inputFiles2.current?.get_files().length == 0
        ? _prevForm?.imageId
        : await this.get_firstPhotoFromImage(
          _form.inputFiles2.current?.get_files()
        );
    form.jsonContent.features = this.convert_featuresToDTO(
      _form.features.current.state.value
    );
    form.jsonContent.carInfo = {
      doors: _form.doors.current?.value || "",
      seats: _form.seats.current?.value ? _form.seats.current.value : "",
      fuel: _form.fuel.current?.value ? _form.fuel.current.value : "",
      gearbox: _form.gearbox.current?.value ? _form.gearbox.current.value : "",
    };
    form.jsonContent.distanceLimit = _form.distanceLimit.current?.value
      ? _form.distanceLimit.current.value
      : "";
    form.jsonContent.price = {
      PLN: _form.nettoPrice.current?.value
        ? _form.nettoPrice.current.value
        : "",
      EUR: _form.nettoPrice.current?.value
        ? _form.nettoPrice.current.value
        : "",
    };
    form.jsonContent.offerType = _form.offerType.current?.value
      ? _form.offerType.current.value
      : "";
    form.jsonContent.distanceLimit = _form.distanceLimit.current?.value
      ? _form.distanceLimit.current.value
      : "";
    form.jsonContent.overrunPrice = {
      PLN: _form.distanceExceeding.current?.value
        ? _form.distanceExceeding.current.value
        : "",
      EUR: _form.distanceExceeding.current?.value
        ? (parseFloat(_form.distanceExceeding.current.value) / 0.23).toString()
        : "",
    };
    _form.hook.current?.value &&
      (form.jsonContent.hooks = UsedCarsService.set_valueToArrayElements(
        _form.hook.current?.value
      ));
    form.jsonContent.usedCarElements = {
      registrationNumber:
        _form.registrationNumber.current?.value &&
        _form.registrationNumber.current.value.trim(),
      addicionalEquipment: this.convert_addicionalEquipmentToDTO(
        _form.addicionalEquipment.current.state.value
      ),
      packages: {
        head:
          _form.packages.head.current?.value &&
          _form.packages.head.current.value,
        content: _form.packages.content.current?.value && [
          _form.packages.content.current?.value,
        ],
      },
      carDescription: _form.carDescription.current?.value
        ? _form.carDescription.current.value
        : "",
      images:
        _form.inputFiles.current?.get_files().length == 0
          ? _prevForm?.images
          : await this.convert_imageToDTO(
            _form.inputFiles.current?.get_files()
          ),
      url: `${NavigationService.create_LinkStandard(
        _form.offerName?.current?.value.trim()
      )}`,
    };
    _form.packages.head.current?.value == "" &&
      _form.packages.content.current?.value == "" &&
      delete form.jsonContent.usedCarElements.packages;
    return form;
  }

  public static map_fromDTO(_usedCarsDTO) {
    const arrayOfUsedCars: Array<UsedCarsModel> = [];
    _usedCarsDTO.forEach((e: any, i) => {
      arrayOfUsedCars.push({
        id: e.id,
        state: e.status,
        offerName: e.jsonContent?.title,
        offerNameDetails: e.jsonContent?.subtitle,
        registrationNumber: e.jsonContent?.usedCarElements?.registrationNumber,
        imageId: e.jsonContent?.imageId,
        offerType: e.jsonContent?.offerType,
        features: e.jsonContent?.features,
        doors: e.jsonContent?.carInfo?.doors,
        seats: e.jsonContent?.carInfo?.seats,
        fuel: e.jsonContent?.carInfo?.fuel,
        gearbox: e.jsonContent?.carInfo?.gearbox,
        distanceLimit: e.jsonContent?.distanceLimit,
        nettoPrice: e.jsonContent?.price?.PLN,
        bruttoPrice: e.jsonContent?.price?.PLN,
        distanceExceeding: e.jsonContent?.overrunPrice?.PLN,
        hook: e.jsonContent?.hooks && e.jsonContent?.hooks[0],
        addicionalEquipment:
          e.jsonContent?.usedCarElements?.addicionalEquipment,
        packages: {
          head: e.jsonContent?.usedCarElements?.packages?.head as string,
          content: e.jsonContent?.usedCarElements?.packages?.content[0],
        },
        carDescription: e.jsonContent?.usedCarElements?.carDescription,
        images: e.jsonContent?.usedCarElements?.images,
      });
    });
    return arrayOfUsedCars;
  }
  //mapper dla modelu samochodu uzywanego w nowym formularzu
  public static map_newCarsFromModel(newCars) {
    const mappedArray: Array<ITableCarsModel> = [];

    newCars.carGroupDtoPaginatedList.results.forEach((e: ICarLac) => {
      mappedArray.push({
        erboId: e.carGroupId,
        rmsId: e.carGroupRmsId,
        offerErboId: e?.offerErboId,
        name: e.carGroupName,
        status: e.status,
        numberOfCars: e.numberOfCars,
        carLogicWarehouse: e.carLogicWarehouse,
        visibility:e.visibility
      });
    });
    return mappedArray;
  }
  public static map_getCarByID(singleCar) {
    const mappedArray: Array<NewCarsModel> = [];
    mappedArray.push({
      id: singleCar.carGroupDto.id,
      rmsId: singleCar.carGroupDto.rmsId,
      status: singleCar.carGroupDto.status,
      name: singleCar.carGroupDto.name,
      detailedName: singleCar.carGroupDto.detailedName,
      gearbox: singleCar.carGroupDto.gearbox,
      fuelType: singleCar.carGroupDto.fuelType,
      doorsNumber: singleCar.carGroupDto.doorsNumber,
      seatsNumber: singleCar.carGroupDto.seatsNumber,
      cars: singleCar.carGroupDto.cars,
      priceLists: singleCar.carGroupDto.carPriceLists,
      equipments: singleCar.carGroupDto.carEquipments,
      notMainImagesIds: singleCar?.carGroupDto?.carGroupImageInformation?.notMainImagesIds ?? singleCar?.carGroupDto?.notMainImagesIds,
      notMainImagesUrls: singleCar?.carGroupDto?.carGroupImageInformation?.notMainImagesUrls ?? [],
      mainImageId: singleCar?.carGroupDto?.carGroupImageInformation?.mainImageId,
      mainImageUrl: singleCar?.carGroupDto?.carGroupImageInformation?.mainImageUrl,
    });
    return mappedArray;
  }
}

// dodanie mapperów dla react Select w komponencie NewFormView
export const getFormatArrayFeaturesForReactSelect = (data: Array<any>) => {
  // console.log(data);
  let featuresArray: Array<{ label: string; value: any }> = [];
  data &&
    data[0].equipments.map((el) => {
      featuresArray.push({ value: el.id, label: el.description });
    });
  return featuresArray;
};

export const getFormatArrayLocationIdForReactSelect = (data: Array<any>) => {
  let formatArray: Array<{ label: string; value: any }> = [];
  data &&
    data[0].cars.map((el): void => {
      formatArray.push({
        value: el.id,
        label: `${el.rmsId} ${el.carLocationName}`,
      });
    });
  return formatArray;
};

export const getFormatArrayIdCarsForReactSelect = (data: Array<any>) => {
  let IdArrays: Array<{ label: string; value: any }> = [];
  data &&
    data[0].cars.map((el): void => {
      IdArrays.push({
        label: el.rmsId,
        value: el.id,
      });
    });
  return IdArrays;
};
