import Compressor from 'compressorjs'; // ten do użycia -> https://www.npmjs.com/package/compressorjs

export class ImageCompressionService {

  public static async CI(_imageFile: File): Promise<File> {

    const options = {
      quality: 0.8,
      mimeType: 'image/webp',
      type: 'image/webp',
      maxWidth: 2000,
      maxHeight: 2000,
      width: 1000,
      height: 1000,
      minWidth: 500,
      minHeight: 500,
      convertSize: Infinity,
      loose: true,
      redressOrientation: true,

      // Callback before compression
      beforeCompress: function (result) {
        // console.log('Image size before compression:', result.size);
        // console.log('mime type:', result.type);
      },


      // Compression success callback
      success: function (result: File) {
        // console.log('Image size after compression:', result.size);
        // console.log('mime type:', result.type);
        // console.log('Actual compression ratio:', ((_imageFile.size - result.size) / _imageFile.size * 100).toFixed(2) + '%');
        return result
      },

      // An error occurred
      error: function (msg) {
        console.error(msg);
      }
    }

    const changeExtension = (_imageFile: File, extension: string): string => {
      // let pos = _imageFile.name.lastIndexOf(".");
      // let newName = _imageFile.name.substr(0, pos < 0 ? _imageFile.name.length : pos) + `.${extension}`;
      // const imageName = _imageFile.name.split('.').slice(0, 1).join('.')+`.${extension}` 
      return _imageFile.name.split('.').slice(0, 1).join('.')+`.${extension}`
    }

    return new Promise((resolve) => {
      new Compressor(_imageFile, {
        ...options,
        success: (result) => {
          console.log('Image size before compression:', _imageFile.size / 1024);
          console.log('mime type:', _imageFile.type);
          console.log('Image size after compression:', result.size / 1024);
          console.log('mime type:', result.type);
          console.log('Actual compression ratio:', ((_imageFile.size - result.size) / _imageFile.size * 100).toFixed(2) + '%');
          console.log(changeExtension(_imageFile, 'webp'))
          resolve(new File([result], changeExtension(_imageFile, 'webp'), { type: options.type }))
        }
      })
    })
  }

}