import React from "react"
import { Route, Switch } from "react-router-dom"
import OnlineCheckInDetail from '../Views/OnlineCheckInDetail.View'
import OnlineCheckinList from '../Views/OnlineCheckinList.View'

const OnlineCheckInRoutes = () =>  (
  <Switch>
    <Route exact path="/online-check-in/:erboId" component={OnlineCheckInDetail}></Route>
    <Route exact path="/online-check-in" component={OnlineCheckinList}></Route>
  </Switch>
)

export default OnlineCheckInRoutes
