import React, { FC } from 'react'
import { combineClasses } from '../../../../external.module/CMSComponents.Module/Services/CssServices'
import styles from './Section.module.scss'

type ClassesSet = {
  /** Rozszerza/nadpisuje style najwyższego elementu w drzewie komponentu. */
  root: string
}

interface INestedClasses {
  readonly [key: string]: Partial<INestedClasses> | string
}

export interface IStyledComponent<Classes extends INestedClasses = ClassesSet> {
  /** Rozszerza/nadpisuje style różnych części komponentu. */
  classes?: Partial<Classes & ClassesSet>
  /** Definiuje klasę nadawanę najwyższemu elementowi w strukturze komponentu. */
  className?: string
}

interface ISection extends IStyledComponent {
  id?: string
}

export type SectionProps = ISection

const Section: FC<SectionProps> = ({
  children,
  className,
  classes,
  id
}) => (
  <section className={combineClasses(styles.section, classes?.root, className)} id={id}>
    {children}
  </section>
)

export default Section
