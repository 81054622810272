import { CarsStatusEnum } from "../Enums/CarStatus.Enum";

interface IAction {
  type: string;
  payload: number | string;
}

export const initialState = {
  Magazine: "",
  AllCars: "",
  Published: "",
  Reserved: "",
  handed_over: "",
  Unpublished: "",
};

const countReducers = (prevState, action: IAction) => {
  switch (action.type) {
    case CarsStatusEnum.UNPUBLISHED:
      return {
        ...prevState,
        Magazine: action.payload,
      };
    case CarsStatusEnum.PUBLISHED:
      return {
        ...prevState,
        Published: action.payload,
      };
    case CarsStatusEnum.RESERVED:
      return {
        ...prevState,
        Reserved: action.payload,
      };
    case CarsStatusEnum.ALL:
      return {
        ...prevState,
        AllCars: action.payload,
      };
    case CarsStatusEnum.HANDED_OVER:
      return {
        ...prevState,
        handed_over: action.payload,
      };
    // case CarsStatusEnum.IN_MAGAZINE:
    //   return {
    //     ...prevState,
    //     Magazine: action.payload,
    //   };
  }
};

export default countReducers;
