export enum UsedCarsFeatures {
    F1 = "w cenie wynajmu ubezpieczenie i usługi zapewniające komfort eksploatacji pojazdu",
    F2 = "możliwość wyboru dodatkowych pakietów ochronnych",
    F3 = "możliwość zmiany limitu kilometrów w trakcie trwania umowy",
    F4 = "niskie opłaty za nadprzebieg",
    F5 = "oferta wynajmu na  12 miesięcy",
    F6 = "elastyczne warunki umowy - miesięczny okres wypowiedzenia",
    F8 = "pełna obsługa techniczna samochodu w cenie",
    F9 = "miesięczny okres wypowiedzenia",
    F10 = "oferta ograniczona dostępnością samochodów",
    F11 = "1 miesięczna opłata za rezygnację z umowy z 14 dniowym okresem wypowiedzenia",
    F12 = "oferta wynajmu na 6 miesięcy",
    F13 = "koszt 1km to tylko 1,45zł netto",
    F14 = "bez limitu kilometrów",
    F15 = "elastyczne rozliczanie za rzeczywisty przebieg"
}


export const FeaturesDictionary = [
    {value: UsedCarsFeatures.F1, label: UsedCarsFeatures.F1},
    {value: UsedCarsFeatures.F2, label: UsedCarsFeatures.F2},
    {value: UsedCarsFeatures.F3, label: UsedCarsFeatures.F3},
    {value: UsedCarsFeatures.F4, label: UsedCarsFeatures.F4},
    {value: UsedCarsFeatures.F5, label: UsedCarsFeatures.F5},
    {value: UsedCarsFeatures.F6, label: UsedCarsFeatures.F6},
    {value: UsedCarsFeatures.F8, label: UsedCarsFeatures.F8},
    {value: UsedCarsFeatures.F9, label: UsedCarsFeatures.F9},
    {value: UsedCarsFeatures.F10, label: UsedCarsFeatures.F10},
    {value: UsedCarsFeatures.F11, label: UsedCarsFeatures.F11},
    {value: UsedCarsFeatures.F12, label: UsedCarsFeatures.F12},
    {value: UsedCarsFeatures.F13, label: UsedCarsFeatures.F13},
    {value: UsedCarsFeatures.F14, label: UsedCarsFeatures.F14},
    {value: UsedCarsFeatures.F15, label: UsedCarsFeatures.F15}
]