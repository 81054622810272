import React from 'react'
import FilterToggle from '../../../../Common/Components/Filter/FilterToggle.Component'
import FilterButton, { ISelectProps } from '../../../../Common/Components/Filter/FilterButton.Component'
import { CarVisibility } from '../../Enums/CarStatus.Enum'

export type OnSelectVisibility = ISelectProps['onChange']

interface IButtons<Union> {
  title: string
  count: number
  groupTitle?: string
  type: 'all' | Union
}

interface IProps<T extends string = string> {
  onSelectButton?(type: string): void;
  activeButton: string;
  onSelectVisibility?: OnSelectVisibility
  //ToDo: nazwa do zmiany
  buttons: IButtons<T>[]
  showFilters?: boolean
}

const visibilityOptions = [
  {
    label: 'Wszystkie',
    value: CarVisibility.ALL
  },
  {
    label: 'Widoczne',
    value: CarVisibility.VISIBLE
  },
  {
    label: 'Ukryte',
    value: CarVisibility.HIDDEN
  },
]

const ButtonWithOffers: React.FC<IProps> = ({
  activeButton,
  onSelectVisibility,
  onSelectButton ,
  buttons,
  showFilters
}) => {
  const groups = buttons
    .reduce<Record<string, NonNullable<typeof buttons & { groupTitle?: string }>>> ((result, button) => {
      const groupType = button.type ?? ''
      result[groupType] ??= []
      result[groupType].push(button)
      result[groupType].groupTitle ??= button.groupTitle

      return result
    }, {})

  return (
    <div className="filter-groups">
      {Object.entries(groups).map(([groupType, btns]) => (
        <div className="group" key={groupType}>
          <h3>{btns.groupTitle}</h3>
          <div className="group__buttons-wrapper">
            {btns.map((button, idx) => (
              <button
                key={idx}
                onClick={() => onSelectButton?.(button.title)}
                className={`group__button${activeButton === button.title ? "--active" : ""}`}
              >
                {button.title}
                &nbsp;
                {button.count}
              </button>
            ))}
          </div>
        </div>
      ))}
      {showFilters
        ? (
          <FilterToggle className={{ button: 'cars__filters-toggle-button', filters: 'cars__filters' }}>
            <FilterButton
              name="Widoczność"
              options={visibilityOptions}
              initialValue={visibilityOptions[0]}
              onChange={onSelectVisibility}
            />
          </FilterToggle>
        ) : null}
    </div>
  );
};

export default ButtonWithOffers;
