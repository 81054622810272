import UsedCarsConnector from "../Connectors/UsedCarsConnector";
import UsedCarsModel from "../Models/UsedCars.Model";
import { UsedCarsFeatures } from "../Enums/UsedCarsFeatures.Enum";
import UsedCarsMapper from "../Mappers/UsedCars.Mapper";
import ICarGroupDTO from "../Models/CarGroupDTO.Model";
import LACOfferRepository from "../../LACOffers/Repositories/LACOffer.Repository";
import LACOfferService from "../../LACOffers/Services/LACOffer.Service";

export default class UsedCarsService {
  private static convertFeatures(
    _features: Array<any>
  ): Array<{ value: string; label: string }> {
    if (_features.length > 0) {
      let newArray: Array<{ value: string; label: string }> = [];

      // _features.map("")
    }
    return [];
  }

  public static async get_allUsedCars() {
    const lacCarArray: ICarGroupDTO = await UsedCarsConnector.fetchUsedCars();
    return lacCarArray.carGroupDtoPaginatedList.results;
  }

  // ToDo: do usunięcia jeśli w ogóle nie jest używane
  // public static async get_allNewCars() {
  //   const arrayOfUsedCars: Array<UsedCarsModel> | any =
  //     await UsedCarsConnector.fetchNewCarsData();
  //   if (arrayOfUsedCars.errorCode || arrayOfUsedCars === undefined) {
  //     alert(arrayOfUsedCars.errorCode);
  //   } else {
  //     const mappedData = UsedCarsMapper.map_newCarsFromModel(arrayOfUsedCars);
  //     return mappedData;
  //   }
  // }

  public static async save_usedCar(_form, _prevForm, _id?: number) {
    if (_id) {
      return this.update_usedCar(_form, _prevForm, _id);
    } else {
      return this.set_usedCar(_form);
    }
  }

  public static async set_usedCar(_form) {
    const usedCarDTO = await UsedCarsMapper.map_toDTO(_form);
    await UsedCarsConnector.set_usedCar(usedCarDTO);
  }

  public static async update_usedCar(_form, _prevForm, _usedCarId: number) {
    const usedCarDTO = await UsedCarsMapper.map_toDTO(_form, _prevForm);
    // console.log(usedCarDTO);
    await UsedCarsConnector.update_usedCar(usedCarDTO, _usedCarId);
  }

  public static async delete_usedCar(_usedCarId: number) {
    await UsedCarsConnector.delete_usedCar(_usedCarId);
  }

  public static set_valueToArrayElements(_hook: string) {
    if (_hook) {
      let valuesArray: Array<{ value: string }> = [];
      const newHook: { value: string } = {
        value: _hook,
      };
      valuesArray.push(newHook);
      return valuesArray;
    }
  }

  public static async get_usedCarFromAllUsedCars(_carId: number) {
    const arrayOfCars: any = await this.get_allUsedCars();
    const car: UsedCarsModel = arrayOfCars.find(
      (item) => item.id == _carId
    ) as UsedCarsModel;
    return car;
  }

  public static get_features() {
    let featuresArray: Array<{ label: string; value: any }> = [];
    Object.keys(UsedCarsFeatures).map((e, i) => {
      featuresArray.push({ value: e.toString(), label: UsedCarsFeatures[e] });
    });

    return featuresArray;
  }

  public static async get_carGroupData(carId: number | string) {
    const singleCar = await UsedCarsConnector.fetchDataCarsById(
      carId
    );
    const data = UsedCarsMapper.map_getCarByID(singleCar);
    LACOfferRepository.priceListIds = []
    data.forEach(car => { car.priceLists?.forEach(priceList => {
      if (priceList.active === true)
      {LACOfferService.set_priceElementToPriceList(priceList.id)}
      if (priceList.main === true)
      {LACOfferService.set_mainPriceListId(priceList.id)}
    })})
    return data;
  }
}
