import AuthorizationService from "../../Authorization.Module/Services/Authorization.Service";

export default class LACOfferConnector {
  public static async POST_offer(_offer) {
    const r = await fetch(process.env.REACT_APP_API_URL_LAC + "/offer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
      body: JSON.stringify(_offer),
    });
    return r;
  }

  public static async EDIT_offer(_id, _offer) {
    const response = await fetch(process.env.REACT_APP_API_URL_LAC + "/offer/" + _id, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
      body: JSON.stringify(_offer),
    });
    return response;
  }

  public static async EDIT_visibility(_id, _visibility) {
    const response = await fetch(process.env.REACT_APP_API_URL_LAC + "/offer/car-group/" + _id + "/visibility?visibility=" + _visibility, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      }
    });
    return response;
  }

  public static async GET_offer(_id: string | number){
    const response = await fetch(process.env.REACT_APP_API_URL_LAC + "/offer/" + _id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
    })
    const data = await response.json()
    return await data
  }

  public static async GET_offerID(_groupID: string | number){
    const response = await fetch(process.env.REACT_APP_API_URL_LAC + "/offer/group/" + _groupID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`
      } 
    })
    const data = await response.json()
    return await data}
}
