import React, { useEffect } from 'react'
import Select, { StylesConfig, components, Props as SelectProps } from 'react-select'
import './Filter.scss'
import { ReactComponent as DropDownIcon } from '../../../assets/BlueIconForReactSelect.svg'

interface IItem {
  value: string
  label: string
}

export interface ISelectProps {
  id?: string
  name?: string
  options: Array<IItem>
  onChange?: SelectProps['onChange']
  initialValue?: IItem
  dataTestId?: string
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon />
    </components.DropdownIndicator>
  )
}

const customStyles = () => (
  {
    color: '#143F91',
    fontSize: '16px',
    fontWeight: '600',
    zIndex: 2,
  }
)
const colourStyles: StylesConfig<any, any> = {
  control: (styles) => (
    { ...styles, minWidth: '196px' }
  ),
  singleValue: (styles) => (
    { ...styles, ...customStyles() } as any
  ),
  option: (styles) => (
    { ...styles, backgroundColor: '#fff', ...customStyles() } as any
  ),
  indicatorSeparator: () => (
    { display: 'none' }
  ),
  menu: (styles) => (
    { ...styles, zIndex: 10 }
  ),
}

const FilterButton: React.FC<ISelectProps> = ({
  name,
  id,
  dataTestId,
  initialValue,
  options,
  onChange,
}) => {
  useEffect(() => {
    const selectElement = document.querySelector(`#${id}`)
    if (selectElement && dataTestId) { selectElement.setAttribute('data-test-id', `filter-${dataTestId}-list`) }
  }, [])

  return (
    <div className="filter-car-section__filter-button-container__single">
      <div data-test-id={dataTestId && `filter-${dataTestId}-label`}>{name ?? ''}</div>
      <Select
        id={id}
        className="basic-single"
        classNamePrefix="select"
        name="options"
        placeholder="Wybierz"
        options={options}
        styles={colourStyles}
        components={{ DropdownIndicator }}
        onChange={onChange}
        defaultValue={initialValue}
      />
    </div>
  )
}

export default FilterButton
