import Header from '../../../Common/Components/Header.Component'
import Search from '../../../Common/Components/Search/Search.Component'
import ButtonWithOffers from '../../UsedCars/Views/ButtonsWithOffers/ButtonsWithOffers'
import HeaderTable from '../../../Common/Components/HeaderTable'
import ButtonSelect from '../../../Common/Components/ButtonSelect'
import Pagination from '../../../Common/Components/Pagination'
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { getOnlineCheckIns, getTokenFromUrl } from '../Services/OnlineCheckIn.Service'
import { showErrorSnackbar } from '../../../external.module/CMSComponents.Module/Snackbar'
import editIcon from '../../../assets/editIcon.svg'
import LinkComponent from '../../../Common/Components/Link.Component'
import styles from './OnlineCheckInList.module.scss'
import { IOnlineCheckIn, Status } from '../Models/OnlineCheckIn.Model'
import { statusToIndicatorMap, titleToStatusMap } from '../Constans/OnlineCheckIn.Consts'
import { combineClasses } from '../../../external.module/CMSComponents.Module/Services/CssServices'
import { filtersReducer, initState } from './Filters.Reducer'
import { IFilters } from '../Models/Filters.Model'
import Loader from '../Components/Loader/Loader.Component'

const initPage = 1

const OnlineCheckinList = () => {
  const [rows, setRows] = useState<IOnlineCheckIn[]>([])

  const [activeStatus, setActiveStatus] = useState<string>('Wszystkie')
  const [statusReservationsCounts, setStatusReservationsCounts] = useState({ success: 0, error: 0, all: 0})
  // ToDo: stan powielony z filtrów bo one wyzwalają refetch i potrzebna jest inny nr strony w rq a inny na widoku
  // ToDo: świetny w przypadku resetu strony byłby redux bo można wydzielić konkretny stan a na razie nie widzę tego
  //  przy reducerze reactowym
  const [page, setPage] = useState(initPage)
  const [isLoading, setIsLoading] = useState(false)

  const shouldResetPageRef = useRef(false)
  const resetPage = useCallback(() => {
    setPage(initPage)
  }, [])

  const abortControllerRef = useRef<AbortController>()

  const statusButtons = [
    {
      groupTitle: 'Status Odprawy online',
      title: 'Sukces',
      count: statusReservationsCounts.success,
      type: 'status',
    },
    {
      groupTitle: 'Status Odprawy online',
      title: 'Problemy',
      count: statusReservationsCounts.error,
      type: 'status',
    },
    {
      title: 'Wszystkie',
      count: statusReservationsCounts.all,
      type: 'all',
    },
  ]

  const fetchCheckIns = async (newFilters: IFilters) => {
    setIsLoading(true)
    abortControllerRef.current?.abort()

    abortControllerRef.current = new AbortController()

    const onlineCheckIns = await getOnlineCheckIns(
      newFilters,
      { signal: abortControllerRef.current?.signal }
    )

    if (!onlineCheckIns) {
      setIsLoading(false)
      return
    }

    // ToDo: Śliskie, możliwe problemy w utrzymaniu i nie podoba mi się ale najszybsze do zrobienia
    if (shouldResetPageRef.current) {
      resetPage()
      shouldResetPageRef.current = false
    }
    setRows(onlineCheckIns?.results)
    setTotal(onlineCheckIns?.total)
    setStatusReservationsCounts({
      success: onlineCheckIns?.successes,
      all: onlineCheckIns?.successes + onlineCheckIns?.errors,
      error: onlineCheckIns?.errors
    })
    setIsLoading(false)
  }

  const [filters, refetchData] = useReducer(filtersReducer(fetchCheckIns), initState)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getTokenFromUrl()
      .then(() => refetchData(null))
      .catch(() => {
        showErrorSnackbar('Błąd z uwierzytelnieniem użytkownika')
      })
  }, [])

  return (
    <>
      <Header text="Odprawa Online" isMenuVisible={false}>
        <Search
          classes={{ root: styles['search']}}
          placeholder="Szukaj"
          onChange={(event) => {
            shouldResetPageRef.current = true
            refetchData({
              type: 'GLOBAL_SEARCH',
              payload: event.target.value
            })
          }}
          onReset={() => {
            shouldResetPageRef.current = true
            refetchData({
              type: 'GLOBAL_SEARCH',
            })
          }}
          endAdornment={isLoading && <Loader />}
        />
      </Header>
      <div className={combineClasses("main-table__wrapper", styles['table-wrapper'])}>
        <ButtonWithOffers
          onSelectButton={(statusTitle) => {
            shouldResetPageRef.current = true
            setActiveStatus(statusTitle)
            refetchData({
              type: 'CHANGE_STATUS',
              payload: titleToStatusMap.get(statusTitle)
            })
          }}
          activeButton={activeStatus}
          buttons={statusButtons}
        />
        <div className="table-container">
          <HeaderTable titles={['Numer RWR', 'Status', ' ']} classess={'table-header ' + styles['table-header']} />
          <div className="table-used-cars">
            <div className={styles['table-body']}>
              {rows.map((checkIn) => (
                <div className={styles['table-row']}>
                  <span>{checkIn.rwrNumber}</span>
                  <span className={styles[`status--${checkIn.status?.toLowerCase()}`]}>{statusToIndicatorMap.get(checkIn.status)}</span>
                  <div className={styles['table-action']}>
                    <LinkComponent
                      icon={editIcon}
                      path={`/online-check-in/${checkIn.erboId}`}
                      data={{ rwr: checkIn.rwrNumber }}
                      className={styles['icon__edit']}
                    />
                    {/*<LinkComponent*/}
                    {/*  icon={JumpTo}*/}
                    {/*  className={styles['icon__jump-to']}*/}
                    {/*  path={`${process.env.REACT_APP_RMS_WWW_ADDRESS}/RezerwacjaWydanieListDTO/Edit/${checkIn.rmsId}`}*/}
                    {/*  target*/}
                    {/*/>*/}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="table-used-cars_pagination">
            <div>
              <ButtonSelect
                renderTitle={(count) => `Pokaż ${count} odpraw`}
                rowsPerPage={filters.rowsPerPage}
                total={total}
                handleChange={(rowsPerPage) => {
                  shouldResetPageRef.current = true
                  refetchData({
                    type: 'CHANGE_ROWS_PER_PAGE',
                    payload: rowsPerPage
                  })
                }}
              />
            </div>
            <Pagination
              total={total}
              rowsPerPage={filters.rowsPerPage}
              paginate={(page) => {
                setPage(page)
                refetchData({ type: 'CHANGE_PAGE', payload: page })
              }}
              nextPage={() => {
                setPage((page) => page + 1)
                refetchData({ type: 'NEXT_PAGE' })
              }}
              prevPage={() => {
                setPage((page) => page - 1)
                refetchData({ type: 'PREVIOUS_PAGE' })
              }}
              currentPage={page}
              minNumbers={page < 5 ? 1 : page - 2}
              maxNumbers={page < 5 ? 5 : page + 2}
              pageLimit={4}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default OnlineCheckinList
