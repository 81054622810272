import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Header from '../../../Common/Components/Header.Component'
import { ReactComponent as ArrowSvg } from '../../../assets/arrowLeft.svg'
import StatusBar from '../Components/StatusBar/StatusBar.Component'
import { CHECK_IN_NAV_ITEMS, CheckInNavItems, ICheckInDetails, STATUS } from '../Models/OnlineCheckIn.Model'
import { getOnlineCheckIn, INIT_CHECK_IN } from '../Services/OnlineCheckIn.Service'
import ExpandableSection from '../Components/ExpandableSection/ExpandableSection.Component'
import Status from '../Components/Status/Status.Component'
import { combineClasses } from '../../../external.module/CMSComponents.Module/Services/CssServices'
import { InputLabel, OutlinedInput } from '@material-ui/core'
import styles from './OnlineCheckInDetails.module.scss'
import InputList from '../Components/InputList/InputList.Component'
import { statusIdToTitleMap } from '../Constans/OnlineCheckIn.Consts'

const STATUS_SECTION_CLASSES = {
  expandableContainer: {
    root: styles['expandable-container']
  }
}

const DATA_SECTION_CLASSES = {
  expandableContainer: {
    content: styles['section-content']
  }
}

const OnlineCheckInDetail = () => {
  const { location: { state }, goBack } = useHistory<{ rwr: string }>()
  const { erboId } = useParams<{ erboId: string}>()
  const [checkIn, setCheckIn] = useState<ICheckInDetails>(INIT_CHECK_IN)

  useEffect(() => {
    (async () => {
      const checkInData = await getOnlineCheckIn(erboId)
      setCheckIn(checkInData)
    })()
  }, [])

  return (
    // ToDo: stworzyć komponent layoutu
    <main className={styles['root']}>
      {/* ToDo: Przerobć src/Modules/UsedCars/Components/Sections/HeaderFormSection/Header.Section.tsx na ogólny
           komponent i tutaj użyć, PS wywalić stamtąd bootstrapa ;) */}
      <Header text="Express - Odprawa Online" />
      <div className={styles['content']}>
        <div>
          <button
            onClick={() => { goBack() }}
            className={combineClasses(styles['back-button'], 'header-section__link')}
          >
            <ArrowSvg />
            Powrót do listy
          </button>
        </div>
        <h2 className="header-section__header">Odprawa Online - {state.rwr}</h2>
        <ExpandableSection
          title={(
            <Status status={checkIn.globalStatus} variant="center">
              <span className={styles['status']} style={{ padding: 0 }}>
                Status Odprawy Online
              </span>
            </Status>
            )}
          classes={STATUS_SECTION_CLASSES}
        >
          <StatusBar<CheckInNavItems>
            items={[...checkIn.statuses.values()]}
            disableRelativeToBarScrolling
          />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.PERSONAL_DATA)?.status}>
              <span className={styles['status']}>
                1. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.PERSONAL_DATA)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.PERSONAL_DATA}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.personalData} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.ADDRESS_DATA)?.status}>
              <span className={styles['status']}>
                2. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.ADDRESS_DATA)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.ADDRESS_DATA}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.addressData} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT)?.status}>
              <span className={styles['status']}>
                3. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.identityDocument} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE)?.status}>
              <span className={styles['status']}>
                4. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.DRIVING_LICENSE}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.drivingLicense} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE)?.status}>
              <span className={styles['status']}>
                5. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.drivingLicenseAcceptance} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE)?.status}>
              <span className={styles['status']}>
                6. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.agreementAcceptance} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE)?.status}>
              <span className={styles['status']}>
                7. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.consentAcceptance} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.TO_PAID)?.status}>
              <span className={styles['status']}>
                8. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.TO_PAID)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.TO_PAID}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.toPaid} />
        </ExpandableSection>
        <ExpandableSection
          title={(
            <Status status={checkIn.statuses.get(CHECK_IN_NAV_ITEMS.DEPOSIT)?.status}>
              <span className={styles['status']}>
                9. {statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DEPOSIT)}
              </span>
            </Status>
          )}
          id={CHECK_IN_NAV_ITEMS.DEPOSIT}
          classes={DATA_SECTION_CLASSES}
        >
          <InputList items={checkIn.deposit} />
        </ExpandableSection>
      </div>
    </main>
  )
}

export default OnlineCheckInDetail
