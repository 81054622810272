import { IStatusBar } from '../Components/StatusBar/StatusBar.Model'
import { Inputs } from '../Components/InputList/InputList.Model'
import { INIT_STATUS_ID_MAP, StatusItemMap } from '../Constans/OnlineCheckIn.Consts'

export const STATUS = {
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
  UNKNOWN: 'UNKNOWN',
} as const

export type Status = keyof typeof STATUS

export const CHECK_IN_NAV_ITEMS = {
  PERSONAL_DATA: 'PERSONAL_DATA',
  ADDRESS_DATA: 'ADDRESS_DATA',
  IDENTITY_DOCUMENT: 'IDENTITY_DOCUMENT',
  DRIVING_LICENSE: 'DRIVING_LICENSE',
  DRIVING_LICENSE_ACCEPTANCE: 'DRIVING_LICENSE_ACCEPTANCE',
  AGREEMENT_ACCEPTANCE: 'AGREEMENT_ACCEPTANCE',
  CONSENT_ACCEPTANCE: 'CONSENT_ACCEPTANCE',
  TO_PAID: 'TO_PAID',
  DEPOSIT: 'DEPOSIT',
} as const

export type CheckInNavItems = keyof typeof CHECK_IN_NAV_ITEMS

export const PAYMENT_STATUS = {
  NEW: 'NEW',
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
  PENDING: 'PENDING',
  WAITING_FOR_NOTIFICATION: 'WAITING_FOR_NOTIFICATION',
  PREAUTHORIZED: 'PREAUTHORIZED',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  ERROR_CONNECTION: 'ERROR_CONNECTION',
  ERROR_INVALID_CHECKSUM: 'ERROR_INVALID_CHECKSUM',
  REJECTED: 'REJECTED',
  READY_TO_PAY: 'READY_TO_PAY'
} as const

export type PaymentStatus = keyof typeof PAYMENT_STATUS

export const DRIVING_LICENSE_STATUS = {
  DURING_VERIFICATION: 'DURING_VERIFICATION',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  NOT_DELIVERED: 'NOT_DELIVERED',
} as const

export type DrivingLicenseStatus = keyof typeof DRIVING_LICENSE_STATUS

export const ID_TYPE = {
  UNKNOWN: 'UNKNOWN',
  ID_CARD: 'ID_CARD',
  PASSPORT: 'PASSPORT',
} as const

export type IdType = keyof typeof ID_TYPE

interface IValueWithError<Value> {
  value?: Value
  error?: string[]
}

interface IToPaid {
  hasFullyPaid?: IValueWithError<boolean>
  hasWaitingPaymentInP24?: IValueWithError<boolean>
}

interface IAddressData {
  country?: IValueWithError<string>
  street?: IValueWithError<string>
  city?: IValueWithError<string>
  postCode?: IValueWithError<string>
  houseNumber?: IValueWithError<string>
}

interface IAgreementAcceptance {
  hasAcceptedAgreement?: IValueWithError<boolean>
}

interface IDeposit {
  cardNumber?: IValueWithError<number>
  cardExpiryDate?: IValueWithError<string>
  cardHolderFirstName?: IValueWithError<string>
  cardHolderLastName?: IValueWithError<string>
  cardHolderEmail?: IValueWithError<string>
}

interface IDrivingLicense {
  country?: IValueWithError<string>
  drivingLicenseNumber?: IValueWithError<string>
  expiryDate?: IValueWithError<null | string>
  grantDate?: IValueWithError<null | string>
  isInternational?: IValueWithError<null | string>
}

interface IDrivingLicenseAcceptance {
  hasAcceptedByRMS?: IValueWithError<boolean>
}

interface IIdentityDocument {
  identityType?: IValueWithError<string>
  identityNumber?: IValueWithError<string>
  country?: IValueWithError<string>
  pesel?: IValueWithError<string>
}

interface IPersonalData {
  firstName?: IValueWithError<string>
  lastName?: IValueWithError<string>
  phone?: IValueWithError<string>
  email?: IValueWithError<string>
  erboId?: IValueWithError<string>
}

export interface ICheckInDetails {
  addressData: Inputs<keyof IAddressData>
  agreementAcceptance: Inputs<keyof IAgreementAcceptance>
  consentAcceptance: Inputs<string>
  deposit: Inputs<keyof IDeposit>
  drivingLicense: Inputs<keyof IDrivingLicense>
  drivingLicenseAcceptance: Inputs<keyof IDrivingLicenseAcceptance>
  globalStatus: Status
  identityDocument: Inputs<keyof IIdentityDocument>
  personalData: Inputs<keyof IPersonalData>
  statuses: StatusItemMap
  toPaid: Inputs<keyof IToPaid>
}

export interface IOnlineCheckIn {
  erboId: string
  rmsId: number
  url: string
  status?: Status
  rwrNumber: string
}
