import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "../../../external.module/CMSComponents.Module/Snackbar/Snackbar.Service";
import { formCustomerConnector, getUserDataConnector } from "../Connectors/FormCustomer.Connector";
export const formCustomer = (email:string, emailToSend:string) => {
     const form ={
      customerEmail:email, customerToSendEmail:emailToSend
     }
  return formCustomerConnector(form)
    .then((res) => {
      res.ok ? showSuccessSnackbar("Wysłano aktywacyjny email") :showErrorSnackbar("Nie udało się zresetować hasła")
    })

};

export const getUserData=(idUser:string)=>{
  const query = new URLSearchParams({
    customerId: idUser,
  });
  return getUserDataConnector(query)
  .then((res) =>res.ok ?  res.json() : showErrorSnackbar("Nie udało się pobrać informacji o użytkowniku"))
}


export const emailValidate=(email: string)=> {
  const isAlmostSyntaxCorrectly = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,4})$/.test(email)
  const doesNotHaveDigitalOnlyDomainEnd = !/(\.\d*)$/.test(email)
  const doesNotHaveHyphenOnStartEndLocalAndDomainPart = /^[^-_]+.*[^-_]+@[^-_]+.*[^-_]+\.[^-_]+.*[^-_]+$/.test(email)
  const doesNotHaveDigitOnStartEmail = /^\D.*/.test(email)
  const lastAtSignIndex = email.lastIndexOf('@')
  const hasShortenLocalPartThan64 = email.slice(0, lastAtSignIndex >= 0 ? lastAtSignIndex : undefined).length <= 64
  const isShortenThan255 = email.length <= 255
  if (isAlmostSyntaxCorrectly
    && doesNotHaveDigitalOnlyDomainEnd
    && doesNotHaveHyphenOnStartEndLocalAndDomainPart
    && doesNotHaveDigitOnStartEmail
    && hasShortenLocalPartThan64
    && isShortenThan255) {
    return true
  } else {
    return false
  }
}