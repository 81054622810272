import React, { useEffect, useState } from "react";
import PlateComponent from "../../../../Common/Components/Plate.Component";
import { Button, InputLabel, TextField } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { emailValidate, formCustomer, getUserData } from "../../../FormCustomer/Services/FormCustomer";
import AuthorizationService from "../../../Authorization.Module/Services/Authorization.Service";
import { useParams } from "react-router-dom";


interface IUserModel {
  id: number| null, 
  rmsId: number | null,
  firstName:string,
  lastName:string,
  email:string
  oldEmail:string
}

const initialState = {
  email: "",
  id: null,
  rmsId: null,
  firstName: "",
  lastName: "",
  oldEmail:""
};

const EditPassword = () => {
  const { idUser } = useParams<{ idUser: string }>();
  const [user, setUser] = useState<IUserModel>(initialState);
  const [error, setError] = useState(false);
  const resetPassword = () => {
    setError(false)
    emailValidate(user.email) ? formCustomer(user.oldEmail,user.email) : setError(true);
  };
  const get_tokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    let token = params.get("token");
    if (token) {
      AuthorizationService.set_token(token);
    }
  };

  useEffect(()=>{
    get_tokenFromUrl()
  if(idUser) {
    getUserData(idUser).then(data=>setUser({...data, oldEmail:data.email})).catch(err=>setUser(initialState))
  }
  },[])
  return (
    <PlateComponent title={`Reset hasła klienta ${user?.oldEmail}`}>
      <>
        <Row className="mt-4">
          <Col xs="8" md="4" style={{ marginBottom: "10px" }}>
            <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
              ID
            </InputLabel>
            <TextField
              name="ID"
              id="outlined-basic"
              value={user.id}
              variant="outlined"
              fullWidth
              disabled
            />
          </Col>
          <Col xs="8" md="4" style={{ marginBottom: "10px" }}>
            <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
              ID RMS
            </InputLabel>
            <TextField
              name="ID RMS"
              id="outlined-basic"
              value={user.rmsId}
              variant="outlined"
              fullWidth
              disabled
            />
          </Col>
          <Col xs="8" md="4" style={{ marginBottom: "10px" }}>
            <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
              EMAIL
            </InputLabel>
            <TextField
              name="EMAIL"
              id="outlined-basic"
              error={error}
              value={user.email}
              onChange={({ target: { value } }) => setUser(prev=>({...prev, email:value}))}
              variant="outlined"
              required
              fullWidth
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="8" md="6" style={{ marginBottom: "10px" }}>
            <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
              IMIĘ
            </InputLabel>
            <TextField
              name="IMIĘ"
              id="outlined-basic"
              value={user.firstName}
              variant="outlined"
              fullWidth
              disabled
            />
          </Col>
          <Col xs="8" md="6" style={{ marginBottom: "10px" }}>
            <InputLabel style={{ fontSize: "14px", fontWeight: 100 }}>
              NAZWISKO
            </InputLabel>
            <TextField
              name="NAZWISKO"
              id="outlined-basic"
              value={user.lastName}
              variant="outlined"
              fullWidth
              disabled
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs="8">
            <Button
              type="button"
              color="default"
              variant="contained"
              onClick={resetPassword}
            >
              Reset hasła użytkownika
            </Button>
          </Col>
        </Row>
      </>
    </PlateComponent>
  );
};

export default EditPassword;
