import React, { useEffect } from "react";
import "./styles/styles.scss";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import AuthorizationService from "./Modules/Authorization.Module/Services/Authorization.Service";
import Snackbar from "./external.module/CMSComponents.Module/Snackbar";
import LacOfferRoute from "./Modules/UsedCars/Routes/LacOffer.Route";
import HomePage from "./Pages/HomePage.Component";
import EditPassword from "./Modules/UsedCars/Views/ResetPassword/EditPassword";
import OnlineCheckInRoutes from './Modules/OnlineCheckIn/Routes/OnlineCheckin.Routes'
import './app.scss'

const App: React.FC = () => {
  const get_tokenFromUrl = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.get("token") != null &&
      AuthorizationService.set_token(urlParams.get("token"));
  };

  useEffect(() => {
    get_tokenFromUrl();
  }, []);

  return (
    <div style={{ backgroundColor: "#f6f6f6", minHeight: "100vh" }}>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route path="/usedCarsList" component={LacOfferRoute}></Route>
          <Route path={`/user/:idUser/password-reset`} component={EditPassword}></Route>
          <Route path="/online-check-in" component={OnlineCheckInRoutes}></Route>
        </Switch>
      </Router>
      <Snackbar />
    </div>
  );
};

export default App;
