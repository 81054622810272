import React from "react";
import LeftArrowPagination from "../../assets/arrowLeftPagination.svg";
import RightArrowPagination from "../../assets/arrowRightPagination.svg";
import LastPageArrow from "../../assets/lastPage.svg";

interface IProps {
  paginate: (numberPage: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  currentPage: number;
  minNumbers: number;
  maxNumbers: number;
  pageLimit: number;
  rowsPerPage: number
  total: number
}

const Pagination: React.FC<IProps> = ({
  paginate,
  nextPage,
  prevPage,
  currentPage,
  minNumbers,
  maxNumbers,
  pageLimit,
  total = 0,
  rowsPerPage = 0
}) => {
  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(total / rowsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="table-used-cars_pagination-single-container">
      <button
        disabled={currentPage === 1}
        onClick={prevPage}
        className="paggination-arrow-button"
      >
        <img
          className="pagination-arrow"
          src={LeftArrowPagination}
          alt="arrowLeftPagination"
        />
      </button>
      {currentPage > pageLimit && (
        <>
          <button
            onClick={() => paginate(1)}
            className="number-page-pagination"
          >
            1
          </button>
          <span>...</span>
        </>
      )}

      {pageNumbers.map((number) => {
        if (number >= minNumbers && number <= maxNumbers)
          return (
            <button
              className={
                number === currentPage
                  ? "number-page-pagination--active"
                  : "number-page-pagination"
              }
              onClick={() => paginate(number)}
              key={number}
            >
              {number}
            </button>
          );
      })}
      {pageNumbers.length >= pageLimit &&
        currentPage <= pageNumbers.length - pageLimit && (
          <>
            <span>...</span>
            <button
              className="number-page-pagination"
              onClick={() => paginate(pageNumbers.length)}
            >
              {pageNumbers.length}
            </button>
          </>
        )}
      <button
        disabled={currentPage === pageNumbers.length}
        onClick={nextPage}
        className="paggination-arrow-button"
      >
        <img
          className="pagination-arrow"
          src={RightArrowPagination}
          alt="arrowRightPagination"
        />
      </button>
    </div>
  );
};

export default Pagination;
