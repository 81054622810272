import React, { Fragment, useRef } from 'react'
import TickIcon from '../TickIcon.Component'
import CloseIcon from '../CloseIcon.Component'

import styles from './StatusBar.module.scss'
import { STATUS } from '../../Models/OnlineCheckIn.Model'
import { IStatusBar, IStatusItem } from './StatusBar.Model'
import Status from '../Status/Status.Component'

function StatusBar<ID extends string>({
  items,
  disableRelativeToBarScrolling
}: IStatusBar<ID>) {
  const navigationRef = useRef<HTMLDivElement | null>(null)

  const handleNavChange = (navItem: IStatusItem<ID>) => {
    const el = document.getElementById(navItem.id)

    const scrollTopOffset = navigationRef.current?.getBoundingClientRect().height ?? 0

    window.scrollTo({ behavior: 'smooth', top: el ? el.offsetTop - scrollTopOffset : scrollTopOffset })
  }

  // Odejmuję 1 z tego względu, że pierwszy element zawsze jest ustawiony i nie ma poprzedzającego go separatora,
  // a pozostałe mają
  const numberOfRepeatedStatuses = items.length - 1

  return (
    <div
      data-test-id="checkin-navigation"
      className={styles['status-bar']}
      ref={(el) => { navigationRef.current = disableRelativeToBarScrolling ? null : el }}
      style={{
        gridTemplateColumns: `93px repeat(${numberOfRepeatedStatuses}, auto 93px)`,
        justifyItems: 'center',
      }}
    >
      {items.map((navItem, idx) => (
        <Fragment key={navItem.id}>
          <button onClick={() => handleNavChange(navItem)} className={styles['status-bar__item']}>
            {navItem.status !== STATUS.INCOMPLETE
              ? <TickIcon className={styles[`status-bar__status-indicator--${navItem.status.toLowerCase()}`]} />
              : <CloseIcon className={styles[`status-bar__status-indicator--${navItem.status.toLowerCase()}`]} />}
            {navItem.label}
          </button>
          {/*<Status status={navItem.status} className={styles['status-bar__item']}>{navItem.label}</Status>*/}
          {idx !== items.length - 1 ? <span className={styles['status-bar__separator']} /> : null}
        </Fragment>
      ))}
    </div>
  )
}

export default StatusBar
