import React from "react"

const HomePage: React.FC = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
          <a href="/usedCarsList">Samochody użytkowe</a>
          <a href="/online-check-in">Odprawa</a>
      </div>
    )
}

export default HomePage
