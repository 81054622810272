import React from 'react'
import styles from './Loader.module.scss'

const Loader = () =>
  <svg width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles['root']}>
    <path d="M19.9424 11C19.9423 12.9005 19.3405 14.7523 18.2233 16.2899C17.1061 17.8274 15.5308 18.9719 13.7233 19.5591C11.9157 20.1464 9.96858 20.1463 8.16103 19.559C6.35347 18.9716 4.77826 17.8271 3.66115 16.2895C2.54403 14.7518 1.94237 12.9 1.94238 10.9994C1.9424 9.09886 2.54409 7.24706 3.66122 5.70945C4.77836 4.17184 6.35359 3.02736 8.16115 2.44003C9.96871 1.8527 11.9158 1.85267 13.7234 2.43995"
      stroke="url(#paint0_linear_1334_816)"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_1334_816"
        x1="14.4426"
        y1="17.8092"
        x2="22.4426"
        y2="8.3092"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#283583" />
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>


export default Loader
