/**
 * Metoda przetwarza tablicę klas na jedną klasę.
 * @example
 * const isActive = true;
 *
 * // poniższe zwróci "switch-item switch-item--active"
 * combineClasses("switch-item", isActive && "switch-item--active");
 * @example
 * const isActive = true;
 * const hasBigLetters = false;
 * const hasFancyFont = false;
 *
 * // poniższe zwróci "switch-item switch-item--active switch-item--big-letters", jak widać klasa
 * // switch-item--fancy-font
 * // nie została uwzględniona w wyniku z tego względu, że warunek nie został spełniony i została pominięta
 * // przy wrzucaniu do niego
 * combineClasses(
 *  "switch-item",
 *  isActive && "switch-item--active",
 *  hasBigLetters ? "switch-item--big-letters" : "switch-item--small-letters",
 *  hasFancyFont && "switch-item--fancy-font"
 * );
 *
 * @param classes - tablica klas i warunków pojawienia się klasy
 * @returns Łańcuch znaków zawierający wszystkie klasy, które mają zostać zaaplikowane do elementu
 */
const combineClasses = (...classes: (string | false | undefined)[]) => classes.filter((cls) => cls).join(' ')

export default combineClasses
