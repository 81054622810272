import {makeAutoObservable} from "mobx"

interface ILacBasicPriceDto {
  netPrice: number | undefined;
  grossPrice: number | undefined;
}
class LACOffersRepository {
  carGroupId: number | undefined;
  description: string | undefined;
  typeText: string | undefined;
  asteriskInformation: string | undefined;
  priceListIds: Array<number> | undefined;
  mainPriceListId: number | undefined;
  modelEquipmentDetail: string | undefined;
  additionalImages: File[] | undefined;
  mainImage: File[] | undefined;
  lacBasicPriceDto: ILacBasicPriceDto | undefined;
  OfferID: number | undefined;

  constructor() {
    this.modelEquipmentDetail = '???'
    makeAutoObservable(this)
  }
}

export default new LACOffersRepository();
