import { useReducer } from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import Slide, { SlideProps } from '@material-ui/core/Slide'
import useCustomEvent from '../../Utility.Module/Hooks/useCustomEvent'
import { ISnackbar, MessagePayload } from './Snackbar.Model'
import { ACTIONS, SNACKBAR_NAME } from './Snackbar.Enum'
import { reducer } from './Snackbar.Service'
import useStyles from './Snackbar.styles'
import { combineClasses } from '../Services/CssServices'
import React from 'react'

const defaultAnchorOrigin: ISnackbar['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' }

/** Komponent wyświetla krótką informację w jednym z rogów ekranu. */
export const Snackbar = ({
  anchorOrigin = defaultAnchorOrigin,
  slideDirection = 'left',
  autoHideDuration = 1000,
  classes,
}: ISnackbar): JSX.Element => {
  const styles = useStyles()
  const [messages, dispatch] = useReducer(reducer, [])

  useCustomEvent<MessagePayload>(SNACKBAR_NAME, ({ detail }) => {
    dispatch({ type: ACTIONS.ADD_MESSAGE, payload: detail })
  })

  return (
    <MuiSnackbar
      classes={{
        root: classes?.root ?? combineClasses(styles.root, messages[0]?.status && styles[messages[0].status]),
        anchorOriginBottomLeft: classes?.anchorOriginBottomLeft ?? combineClasses(styles.bottom, styles.left),
        anchorOriginBottomRight: classes?.anchorOriginBottomRight ?? combineClasses(styles.bottom, styles.right),
        anchorOriginBottomCenter: classes?.anchorOriginBottomCenter ?? combineClasses(styles.bottom, styles.center),
        anchorOriginTopCenter: classes?.anchorOriginTopCenter ?? combineClasses(styles.top, styles.left),
        anchorOriginTopLeft: classes?.anchorOriginTopLeft ?? combineClasses(styles.top, styles.right),
        anchorOriginTopRight: classes?.anchorOriginTopRight ?? combineClasses(styles.top, styles.center),
      }}
      open={!!messages.length}
      message={messages[0]?.message ?? ''}
      key={messages[0]?.id ?? ''}
      onClose={(evt, reason) => {
        if (reason === 'clickaway') { return }
        dispatch({ type: ACTIONS.REMOVE_MESSAGE })
      }}
      autoHideDuration={autoHideDuration}
      anchorOrigin={JSON.stringify(anchorOrigin) === '{}' ? defaultAnchorOrigin : anchorOrigin}
      TransitionComponent={(props: SlideProps) => <Slide {...props} direction={slideDirection} />}
      disableWindowBlurListener
    />
  )
}

export default Snackbar
