import React from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

interface IProps {
    text?: string
    isMenuVisible?: boolean
}

interface IState {
    state: any;
}

export default class Header extends React.Component<IProps, IState> {
    static defaultProps = {
        isMenuVisible: true
    }

    public render() {
        return (
            <AppBar style={{ backgroundColor: '#283583' }} position="static">
                <Toolbar>
                    {this.props.isMenuVisible && <IconButton edge="start" color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>}
                    <Typography variant="h6" >
                        {this.props.text && this.props.text}
                    </Typography>
                    {this.props.children}
                </Toolbar>
            </AppBar>
        )
    }
}
