import React from 'react'
import { IInputList } from './InputList.Model'
import { FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core'
import styles from './InputList.module.scss'

const INPUT_CLASSES = {
  input: styles['input']
}

function InputList<Id extends string>({ items }: IInputList<Id>) {
  return (
    <>
      {items?.map(({
        label,
        value,
        errors,
        id
      }) => (
        <div key={id}>
          <InputLabel className={styles['label']}>{label}</InputLabel>
          <OutlinedInput
            value={value}
            classes={INPUT_CLASSES}
            disabled
            readOnly
            fullWidth
          />
          {errors?.map((error) => <FormHelperText error>{error}</FormHelperText>)}
        </div>
      ))}
    </>
  )
}

export default InputList
