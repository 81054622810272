import AuthorizationService from "../../Authorization.Module/Services/Authorization.Service";
import ICarGroupDTO from "../Models/CarGroupDTO.Model";
import UsedCarsModel, { UsedCarsModelDTO } from "../Models/UsedCars.Model";

export default class UsedCarsConnector {
  //TODO: przywrócić funkcję dopiero gdy skonfigurowany zostanie WebPack i będzie można umieszczać mock w wwwroot

  // public static fetchUsedCars(): Array<UsedCarsModel>{
  //     new Promise<any>((resolve, reject) => {
  //         fetch('../Mock/UsedCarsMock.json')
  //         .then(
  //             (response: Response) => {
  //                 response.json().then((value: any) => {
  //                     resolve(value);
  //                     console.log(value);
  //                     return value;
  //                 });
  //             }
  //         ).catch((error: any) =>{
  //             console.log(error);
  //             reject(error);
  //         }
  //         );
  //     });
  //     return [];
  // }

  public static fetchUsedCarsMock(): Array<UsedCarsModel> {
    const data = require("../../../Mock/UsedCarsMock.json");
    return data.data;
  }

  public static fetchUsedCars() {
    return new Promise<ICarGroupDTO>((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL_LAC + "/car-group?max=1000000", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  }
  public static fetchDataCarsById = async (carId) => {
    const data = await fetch(
      process.env.REACT_APP_API_DATA + `car-group/${carId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return await data;
  };
// ToDo: do usunięcia jeśli w ogóle nie używane
  // public static async fetchNewCarsData() {
  //   const data = await fetch(process.env.REACT_APP_API_DATA + "car-group?max=1000000", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${AuthorizationService.get_token()}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log(data);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  //   return await data;
  // }
  public static async set_usedCar(_usedCar: UsedCarsModelDTO) {
    const r = await fetch(process.env.REACT_APP_API_URL + "used_car_offer/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
      body: JSON.stringify({ offer: _usedCar }),
    });
    console.log(r);
    return await r;
  }

  public static async update_usedCar(_usedCar: UsedCarsModelDTO, _usedCarId) {
    // console.log(_usedCar)
    const r = await fetch(
      process.env.REACT_APP_API_URL + `used_car_offer/${_usedCarId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AuthorizationService.get_token()}`,
        },
        body: JSON.stringify({ offer: _usedCar }),
      }
    );
    return await r.json();
  }

  public static delete_usedCar(_usedCarId: number) {
    fetch(process.env.REACT_APP_API_URL + `used_car_offer/${_usedCarId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
