import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  icon: any;
  path: string;
  // data to dane przekazywane do linku (jakis prymityw sluzacy do odczytu)
  data?: any;
  // dodajemy target kiedy chcemy otworzyc inna witryne w osobnym oknie
  target?: boolean;
  className?: string
}

const LinkComponent = ({
  icon,
  path,
  data,
  target,
  className
}: IProps) => (
  <Link
    to={{ pathname: path, state: data }}
    target={target ? "_blank" : undefined}
    className={className}
  >
    <img className="table-icon" alt={icon} src={icon} />
  </Link>
)
export default LinkComponent;
