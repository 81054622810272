import AuthorizationService from '../../Authorization.Module/Services/Authorization.Service'

export interface IOptions {
  signal?: AbortSignal
}

export const get = async <DTO>(
  endpoint: string,
  queryParams?: URLSearchParams,
  options: IOptions = {}
) => {
  const url = new URL(`${process.env.REACT_APP_API_CMS_REST}${endpoint}`)

  queryParams?.forEach((value, key) => {
    url.searchParams.append(key, value)
  })

  const rq = await fetch(
    url,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthorizationService.get_token()}`,
      },
      signal: options?.signal
    }
  )

  return await rq.json() as DTO
}
