export const _acceptedFormats = [
    'image/*',
    'application/vnd.oasis.opendocument.image',
    'application/vnd.oasis.opendocument.graphics',
];
export const MAX_FILE_SIZE = 37748736;
export const WIDTH = 100;
export const HEIGHT = 100;
export const _errorsMessagesMap = {
    fileIsTooBig: 'Rozmiar pliku jest zbyt duży.',
    notAcceptedFormat: 'Został załadowany plik z nieakceptowanym formatem.',
};
