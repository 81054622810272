import { showErrorSnackbar } from '../../../external.module/CMSComponents.Module/Snackbar'
import { get, IOptions } from '../Connectors/OnlineCheckInList.Connector'
import { CHECK_IN_NAV_ITEMS, ICheckInDetails, IOnlineCheckIn, STATUS } from '../Models/OnlineCheckIn.Model'
import {
  CheckinDetailedStatusResponse,
  CheckinMainDTO,
  ICheckinProperty,
  IOnlineCheckinList,
} from '../Models/OnlineCheckInDTO.Model'
import { Inputs } from '../Components/InputList/InputList.Model'
import {
  ADDRESS_DATA_INPUT_MAP,
  AGREEMENT_ACCEPTANCE_INPUT_MAP,
  CheckInPropMapper,
  DEPOSIT_INPUT_MAP,
  DRIVING_LICENSE_ACCEPTANCE_INPUT_MAP,
  DRIVING_LICENSE_INPUT_MAP,
  FIELDS_ORDER_MAP,
  IDENTITY_DOCUMENT_INPUT_MAP,
  INIT_STATUS_ID_MAP,
  InputMap,
  PERSONAL_DATA_INPUT_MAP,
  statusIdToTitleMap,
  StatusItemMap,
  statusToStringBooleanMap,
  TO_PAID_INPUT_MAP,
} from '../Constans/OnlineCheckIn.Consts'
import { IFilters } from '../Models/Filters.Model'
import AuthorizationService from '../../Authorization.Module/Services/Authorization.Service'

export const debounce = <T, P extends any[]>(cb: (...args: P) => Promise<T>, delay = 500) => {
  let timeout: NodeJS.Timeout;

  return function (...args: P) {
    return new Promise<T>((resolve) => {
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        // @ts-ignore
        const res = await cb.apply(this, args)
        resolve(res);
      }, delay)
    })
  };
}

export const getOnlineCheckIns = debounce(async (filters: IFilters, options: IOptions) => {
  try {
    const queryParams = {
      page: (filters.page - 1).toString(),
      size: filters.rowsPerPage.toString(),
      sortField: 'reservationRmsId',
      sortDirection: 'DESC',
      // W 'completeness' i 'searchTerm' jest '!' by ts nie darł się, że mogę mu przekazać undefined.
      // Nie wiem, ślepy chyba jest, że nie widzi jak usuwam w forEachu klucze z wartościami undefined,
      completeness: statusToStringBooleanMap.get(filters.status)!,
      searchTerm: filters.search!
    }

    Object.entries(queryParams)
      .forEach(([key, value]) => {
        value === undefined && delete queryParams[key]
      })
    const response = await get<IOnlineCheckinList>(
      'reservation/rac/online-checkin',
      new URLSearchParams(queryParams),
      options
    )

    if ('errorCode' in response) {
      throw new Error(response.errorCode as string)
    }

    return {
      results: response.onlineCheckinSummaries.results.map<IOnlineCheckIn>((checkIn) => ({
        erboId: checkIn.checkinId,
        rmsId: checkIn.reservationRmsId,
        url: checkIn.onlineCheckinUrl,
        rwrNumber: checkIn.externalNumber,
        status: checkIn.completeness
      })),
      total: response.onlineCheckinSummaries.totalCount,
      successes: response.onlineCheckinCount?.successCount ?? 0,
      errors: response.onlineCheckinCount?.errorCount ?? 0
    }
  } catch (e) {
    if (
      e
      && typeof e == 'object'
      && 'name' in e
      && e.name === "AbortError"
    ) {
      // ToDo: na ten moment tylko ignorujemy wyłapanie błędu po przerwaniu sygnału
      return
    }
    let msg = 'Użytkownik nie jest autoryzowany do tej akcji'
    // @ts-ignore
    if (e.message !== 'CMS_UNAUTHORIZED') {
      console.error(e)
      msg = 'Błąd podczas pobierania danych'
    }
    showErrorSnackbar(msg)
  }
})

const getStatus = (checkInProps?: ICheckinProperty, showedPropsKeys?: string[]) => {
  if (!checkInProps) {
    return STATUS.INCOMPLETE
  }

  let onlyShowedProps: typeof checkInProps = {}

  if (showedPropsKeys === undefined) {
    onlyShowedProps = checkInProps
  } else {
    showedPropsKeys?.forEach((key) => {
      onlyShowedProps[key] = checkInProps?.[key]
    })
  }

  return Object.values(onlyShowedProps)
    .every((checkInProp) => !(checkInProp as ICheckinProperty)?.faults)
    ? STATUS.COMPLETE
    : STATUS.INCOMPLETE
}

export const getStatuses = (checkIn?: CheckinMainDTO) => {
  if (!checkIn) {
    return INIT_STATUS_ID_MAP
  }

  return new StatusItemMap([
    [
      CHECK_IN_NAV_ITEMS.PERSONAL_DATA,
      {
        id: CHECK_IN_NAV_ITEMS.PERSONAL_DATA,
        status: getStatus(checkIn.userData, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.PERSONAL_DATA)),
        label: `1. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.PERSONAL_DATA)}`,
      }
    ],
    [
      CHECK_IN_NAV_ITEMS.ADDRESS_DATA,
      {
        id: CHECK_IN_NAV_ITEMS.ADDRESS_DATA,
        status: getStatus(checkIn.addressData, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.ADDRESS_DATA)),
        label: `2. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.ADDRESS_DATA)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT,
      {
        id: CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT,
        status: getStatus(checkIn.idDocumentData, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT)),
        label: `3. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.DRIVING_LICENSE,
      {
        id: CHECK_IN_NAV_ITEMS.DRIVING_LICENSE,
        status: getStatus(checkIn.drivingLicense, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE)),
        label: `4. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE,
      {
        id: CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE,
        status: getStatus(checkIn.drivingLicenseStatus, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE)),
        label: `5. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE,
      {
        id: CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE,
        status: getStatus(checkIn.agreement, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE)),
        label: `6. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE,
      {
        id: CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE,
        status: getStatus(checkIn.consents?.documentStatuses, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE)),
        label: `7. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.TO_PAID,
      {
        id: CHECK_IN_NAV_ITEMS.TO_PAID,
        status: getStatus(checkIn.installmentPayment, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.TO_PAID)),
        label: `8. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.TO_PAID)}`,
      },
    ],
    [
      CHECK_IN_NAV_ITEMS.DEPOSIT,
      {
        id: CHECK_IN_NAV_ITEMS.DEPOSIT,
        status: getStatus(checkIn.depositPayment, FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DEPOSIT)),
        label: `9. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DEPOSIT)}`,
      },
    ],
  ])
}

const mapToInputs = (checkInProp: ICheckinProperty | undefined, map: InputMap | CheckInPropMapper, fieldOrder: string[]) => checkInProp
  ? Object.entries(checkInProp)
    .reduce(
      (inputs, [id, { value, faults } = { value: undefined, faults: undefined}], _, checkInPropEntries) => {
        if (typeof map == 'function') {
          inputs.push(
            {
              value: map(value, checkInProp),
              label: id,
              errors: faults,
              id,
            }
          )

          return inputs
        }

        const [label, mapper = (value) => value] = map.get(id) ?? []

        const index = fieldOrder.findIndex((field) => field === id) ?? -1

        if (typeof label == 'undefined') {
          return inputs
        }

        if (index !== -1) {
          inputs[index] = (
            {
              value: mapper(value, checkInProp),
              label,
              errors: faults,
              id,
            }
          )

          return inputs
        }

        return inputs
      },
      [] as Inputs<string>
    )
  : []

export const INIT_CHECK_IN = {
  addressData: [],
  agreementAcceptance: [],
  consentAcceptance: [],
  deposit: [],
  drivingLicense: [],
  drivingLicenseAcceptance: [],
  globalStatus: STATUS.UNKNOWN,
  identityDocument: [],
  personalData: [],
  statuses: getStatuses(),
  toPaid: [],
} as ICheckInDetails

export const getOnlineCheckIn = async (erboId: string) => {
  try {
    const response = await get<CheckinDetailedStatusResponse>(`reservation/rac/online-checkin/${erboId}/detailed-status`)

    if ('errorCode' in response) {
      throw new Error(response.errorCode as string)
    }

    const {
      completeness,
      userData,
      addressData,
      idDocumentData,
      drivingLicense,
      agreement,
      consents,
      installmentPayment,
      drivingLicenseStatus,
      depositPayment,
    } = response.report ?? {}

    return {
      addressData: mapToInputs(
        addressData,
        ADDRESS_DATA_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.ADDRESS_DATA) ?? []
      ),
      agreementAcceptance: mapToInputs(
        agreement,
        AGREEMENT_ACCEPTANCE_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE) ?? []
      ),
      consentAcceptance: mapToInputs(
        { ...(consents?.documentStatuses ?? {}) },
        (value) => value as string,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE) ?? []
      ),
      deposit: mapToInputs(
        { ...depositPayment, 'cardNumber': { value: '' }, 'expiryDate': { value: ''}, 'firstName': { value: ''}, 'lastName': { value: ''}, 'email': { value: ''} },
        DEPOSIT_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DEPOSIT) ?? []
      ),
      drivingLicense: mapToInputs(
        { ...drivingLicense, expiryType: { value: !!drivingLicense?.expiryDate?.faults || !!drivingLicense?.expiryDate?.value }},
        DRIVING_LICENSE_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE) ?? []
      ),
      drivingLicenseAcceptance: mapToInputs(
        drivingLicenseStatus,
        DRIVING_LICENSE_ACCEPTANCE_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE) ?? []
      ),
      globalStatus: completeness,
      identityDocument: mapToInputs(
        idDocumentData,
        IDENTITY_DOCUMENT_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT) ?? []
      ),
      personalData: mapToInputs(
        { ...userData, erboId: { value: erboId } },
        PERSONAL_DATA_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.PERSONAL_DATA) ?? []
      ),
      statuses: getStatuses(response.report),
      toPaid: mapToInputs(
        installmentPayment,
        TO_PAID_INPUT_MAP,
        FIELDS_ORDER_MAP.get(CHECK_IN_NAV_ITEMS.TO_PAID) ?? []
      ),
    } as ICheckInDetails
  } catch (e) {
    let msg = 'Użytkownik nie jest autoryzowany do tej akcji'
    // @ts-ignore
    if (e.message !== 'CMS_UNAUTHORIZED') {
      console.error(e)
      msg = 'Błąd podczas pobierania danych'
    }
    showErrorSnackbar(msg)
    return INIT_CHECK_IN
  }
}

export const getTokenFromUrl = () => new Promise((resolve, reject) => {
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  if (token) {
    AuthorizationService.set_token(token)
    resolve(undefined)
  }

  reject('No token')
})
