import React from "react";

interface IProps {
  titles: string[];
  classess: string
}

const HeaderTable = ({ titles, classess }: IProps) => {
  return (
    <div
      className={classess}
    >
      {titles.map((el, idx) => (
        <h5 key={idx} className="table-header-title">
          {el}
        </h5>
      ))}
    </div>
  );
};

export default HeaderTable;
