import React from 'react';

const TickIcon = ({ className }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.633"
    height="23.633"
    viewBox="0 0 23.633 23.633"
    className={className}
  >
    <g transform="translate(-690.301 -403)">
      <g transform="translate(690.301 403)">
        <circle fill="none" cx="11.816" cy="11.816" r="11.816" transform="translate(0 0)" />
      </g>
      <g transform="translate(697.218 410.69)">
        <g transform="translate(0 0)">
          <path fill="#fff" d="M10.307.11a.4.4,0,0,0-.6,0L3.469,6.288.71,3.589a.4.4,0,0,0-.6,0,.4.4,0,0,0,0,.6h0l3.059,3a.4.4,0,0,0,.6,0L10.307.71a.542.542,0,0,0,0-.6h0C10.127-.07,10.427.29,10.307.11Z" transform="translate(0.19 0.179)" />
          <path fill="#fff" d="M10.377.007h0l-.06-.12h0l-.06-.06H10.2a.635.635,0,0,0-.78.06L3.359,5.946.78,3.366a.652.652,0,0,0-.9,0,.58.58,0,0,0,0,.84l3.059,3a.648.648,0,0,0,.42.18.545.545,0,0,0,.42-.18L10.317.727a.545.545,0,0,0,.18-.42A.458.458,0,0,0,10.377.007Zm-.36.48L3.539,6.965a.225.225,0,0,1-.36,0l-3.059-3a.22.22,0,0,1-.06-.18.22.22,0,0,1,.06-.18.22.22,0,0,1,.18-.06c.06,0,.18,0,.18.06L3.359,6.426l6.3-6.3a.225.225,0,0,1,.36,0h0a.22.22,0,0,1,.06.18C10.137.367,10.077.427,10.017.487Z" transform="translate(0.3 0.281)" />
        </g>
      </g>
    </g>
  </svg>;

export default TickIcon;
