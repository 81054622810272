import React, { useEffect, useReducer, useState } from "react";
import countReducers, { initialState } from "../../Services/CountReducers";
import { CarsStatusEnum, CarVisibility } from '../../Enums/CarStatus.Enum'
import { SelectedButtonTypeCars } from "../../Enums/SelectedButtonTypeCars";
import { CardHeader } from "@material-ui/core";
import ButtonWithOffers, { OnSelectVisibility } from '../ButtonsWithOffers/ButtonsWithOffers'
import Pagination from "../../../../Common/Components/Pagination";
import Header from "../../../../Common/Components/Header.Component";
import UsedCarsService from "../../Services/UsedCarsService";
import UsedCarsData from "../UsedCarsTable/UsedCarsData";
import Search from "../../../../Common/Components/Search/Search.Component";
import ButtonSelect from "../../../../Common/Components/ButtonSelect";
import NewCarsModel from "../../Models/UsedCars.Model";
import LACOfferService from "../../../LACOffers/Services/LACOffer.Service";
import AuthorizationService from "../../../Authorization.Module/Services/Authorization.Service";
import { ITableCarsModel } from "../../Models/TableCars.Model";
import HeaderTable from "../../../../Common/Components/HeaderTable";
import { DeepNonNullable } from 'react-select/src/components'

interface IState {
  newCarsData: Array<NewCarsModel>;
}

const header = [
  "ID",
  "ID Oferty RMS",
  "ID Oferty CMS",
  "Nazwa oferty LAC",
  "Magazyn logiczny",
  "Status",
  "Actions"
];

const headerPublished = [
  "ID",
  "ID Oferty RMS",
  "ID Oferty CMS",
  "Nazwa oferty LAC",
  "Magazyn logiczny",
  "Status",
  "Widoczność",
  "Actions"
];

const headerInWarehouse = [
  "ID",
  "ID Oferty RMS",
  "Nazwa oferty LAC",
  "Magazyn logiczny",
  "Status",
  "Actions"
]

interface IFilters {
  status?: CarsStatusEnum
  visibility?: CarVisibility
}

type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

const MainTable: React.FC<IState> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [carPerPage, setCarPerPage] = useState(10);
  const [pageLimit, setPageLimit] = useState(4);
  const [maxNumbers, setMaxNumbers] = useState(4);
  const [minNumbers, setMinNumbers] = useState(1);
  const [totalCarsNumber, dispatch] = useReducer(countReducers, initialState);
  const [activeButton, setActiveButton] = useState<string>("W magazynie");
  const [newCarsData, setNewCarsData] = useState<Array<ITableCarsModel>>([]);
  const [filters, setFilters] = useState<IFilters>({})

  const get_tokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    let token = params.get("token");

    if (token) {
      AuthorizationService.set_token(token);
    }
  };
  // metoda pozostawia oferty, tzn. grupy samochodów danej marki i modelu, w których są dostępne
  // jakiekolwiek samochody
  const displayCarsMoreThanZero = async (
    cars: Array<ITableCarsModel> | undefined
  ) => {
    let numberCarsMoreThanOne: Array<ITableCarsModel> = [];
    cars &&
      cars.map((el) => {
        el.numberOfCars > 0 && numberCarsMoreThanOne.push(el);
      });
    return numberCarsMoreThanOne;
  };

  useEffect(() => {
    get_tokenFromUrl();
    getCarsByFilters({ status: CarsStatusEnum.UNPUBLISHED });
  }, []);

  //Pagination values
  const indexOfLastCar = currentPage * carPerPage;
  const indexOfFirstCar = indexOfLastCar - carPerPage;
  // const currentCars = data?.slice(indexOfFirstCar, indexOfLastCar);
  const currentCars = newCarsData?.slice(indexOfFirstCar, indexOfLastCar);
  const carPerPageOption = Math.ceil(0 / carPerPage);

  //Functions
  const handleChangeOption = (option) => {
    setCarPerPage(option);
    setCurrentPage(1);
    setMaxNumbers(4);
    setMinNumbers(1);
  };
  const changePage = (numberPage) => {
    setCurrentPage(numberPage);
    if (numberPage === 1) {
      setCurrentPage(1);
      setMaxNumbers(4);
      setMinNumbers(1);
    }
    if (numberPage === carPerPageOption) {
      setMaxNumbers(carPerPageOption);
      setMinNumbers(carPerPageOption - 3);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage >= maxNumbers) {
      setMaxNumbers(maxNumbers + pageLimit);
      setMinNumbers(minNumbers + pageLimit);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
    if (currentPage <= minNumbers) {
      setMaxNumbers(maxNumbers - pageLimit);
      setMinNumbers(minNumbers - pageLimit);
    }
  };

  const getCarsByFilters = async (newFilters: DeepNullable<IFilters>) => {
    const queryFilters = {
      ...filters,
      ...newFilters,
    }
    Object.keys(queryFilters).forEach(key =>
      (queryFilters[key] === null || queryFilters[key] === undefined) && delete queryFilters[key])

    if (queryFilters.status !== CarsStatusEnum.PUBLISHED) {
      delete queryFilters.visibility
    }

    const query = new URLSearchParams(queryFilters as DeepNonNullable<IFilters>).toString();

    const results = await LACOfferService.fetch_offerByStatus(query);
    const carsMoreThanZero =
      newFilters?.status === null
        ? results.newResults
        : await displayCarsMoreThanZero(results.newResults)
    setNewCarsData(carsMoreThanZero);
    if (newFilters?.status === null) {
      dispatch({
        type: CarsStatusEnum.ALL,
        payload: results.totalCount,
      });
    }
    if (newFilters?.status) {
      dispatch({
        type: newFilters.status,
        payload: carsMoreThanZero.length,
      });
    }
    handleChangeOption(10);
    // ToDo: Mogą tu wystąpić błędy gdy na raz się uruchomi kilka wyzwalaczy zmian
    setFilters(queryFilters as DeepNonNullable<IFilters>)
  };
  const selectButton = async (type: SelectedButtonTypeCars) => {
    switch (type) {
      case SelectedButtonTypeCars.PUBLISHED:
        await getCarsByFilters({ status: CarsStatusEnum.PUBLISHED });
        setActiveButton(type);
        break;
      case SelectedButtonTypeCars.RESERVED:
        getCarsByFilters({ status: CarsStatusEnum.RESERVED });
        setActiveButton(type);
        break;
      case SelectedButtonTypeCars.HANDED_OVER:
        getCarsByFilters({ status: CarsStatusEnum.HANDED_OVER });
        setActiveButton(type);
        break;
      case SelectedButtonTypeCars.IN_MAGAZINE:
        getCarsByFilters({ status: CarsStatusEnum.UNPUBLISHED });
        setActiveButton(type);
        break;
      case SelectedButtonTypeCars.ALL:
        getCarsByFilters({ status: null });
        setActiveButton(type);
        break;
    }
  };

  const fetchFilteredOffersByVisibility: NonNullable<OnSelectVisibility> = async (selected) => {
    if (!selected) {
      console.error('Wartość widoczności nie została poprawnie wybrana.')
      return;
    }

    getCarsByFilters( {
      visibility: selected.value === CarVisibility.ALL
        ? null
        : selected.value as CarVisibility,
      status: filters.status ?? null
    })
  }

  return (
    <>
      <Header text="Express - Oferty samochodów używanych" />
      <div className="main-table__wrapper">
        <div className="main-table__header-wrapper">
          <CardHeader
            title="Samochody używane"
            className="main-table__header"
          />
        </div>
        <ButtonWithOffers
          onSelectButton={selectButton}
          activeButton={activeButton}
          onSelectVisibility={fetchFilteredOffersByVisibility}
          showFilters={activeButton === SelectedButtonTypeCars.PUBLISHED}
          buttons={[
            {
              groupTitle: 'Grupy samochodów',
              title: SelectedButtonTypeCars.IN_MAGAZINE,
              count: totalCarsNumber.Magazine,
              type: 'groups'
            },
            {
              groupTitle: 'Oferty',
              title: SelectedButtonTypeCars.PUBLISHED,
              count: totalCarsNumber.Published,
              type: 'offers'
            },
            {
              groupTitle: 'Oferty',
              title: SelectedButtonTypeCars.RESERVED,
              count: totalCarsNumber.Reserved,
              type: 'offers'
            },
            {
              groupTitle: 'Oferty',
              title: SelectedButtonTypeCars.HANDED_OVER,
              count: totalCarsNumber.handed_over,
              type: 'offers'
            },
            {
              title: SelectedButtonTypeCars.ALL,
              count: totalCarsNumber.AllCars,
              type: 'all'
            },
          ]}
        />
        <div className="table-container">
          {activeButton === 'W magazynie' ?
            <HeaderTable titles={headerInWarehouse} classess={'table-header--inWarehouse'} /> :
            activeButton === 'Opublikowane'  || activeButton === 'Wszystkie'?
            <HeaderTable titles={headerPublished} classess={'table-header--published'} /> :
            <HeaderTable titles={header} classess={'table-header--other'} />}
          <div className="table-used-cars">
            <UsedCarsData newCarsData={currentCars} activeButton={activeButton} />
          </div>
          <div className="table-used-cars_pagination">
            <div>
              <ButtonSelect
                total={newCarsData.length}
                handleChange={handleChangeOption}
                rowsPerPage={carPerPage}
                renderTitle={(count) => `Pokaż ${count} samochodów`}
              />
            </div>
            <Pagination
              total={newCarsData.length}
              rowsPerPage={carPerPage}
              paginate={changePage}
              nextPage={nextPage}
              prevPage={prevPage}
              currentPage={currentPage}
              minNumbers={minNumbers}
              maxNumbers={maxNumbers}
              pageLimit={pageLimit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainTable;
