import React, { FC } from 'react'
import ExpandableContainer from '../ExpandableContainer/ExpandableContainer.Component'
import Section, { SectionProps } from '../Section/Section.Component'
import styles from './ExpandableSection.module.scss'
import { IExpandableContainer } from '../ExpandableContainer/ExpandableContainer.Model'

interface IExpandableSection extends Omit<IExpandableContainer, 'classes' | 'className'>, Pick<SectionProps, 'id'>{
  classes?: {
    expandableContainer?: IExpandableContainer['classes'],
    section?: SectionProps['classes']
  }
}

const ExpandableSection: FC<IExpandableSection> = ({
  title,
  children,
  id,
  classes
}) => (
  <Section className={styles['section']} classes={classes?.section} id={id}>
    <ExpandableContainer title={title} classes={classes?.expandableContainer}>{children}</ExpandableContainer>
  </Section>
)

export default ExpandableSection
