import React, { SetStateAction } from "react";
import ParametersGroupCars from "../../Forms/ParametersGroupCars.Form";
import NewCarsModel from "../../Models/NewCarsModel";
import PlateComponent from "../../../../Common/Components/Plate.Component";
import { IParametersGroupCars } from "../../Models/ParametersGroupCars.Model";

const ParametersGroupCarsSection: React.FC<IParametersGroupCars> = ({ car, visibility, setVisible }) => {
  return (
    <>
      {car ? (
        <ParametersGroupCars 
          car={car} 
          visibility = {visibility}
          setVisible={setVisible} />
      ) : (
        <div>Brak parametrów samochodu</div>
      )}
    </>
  );
};
export default ParametersGroupCarsSection;
