import {
  CHECK_IN_NAV_ITEMS,
  CheckInNavItems,
  DRIVING_LICENSE_STATUS,
  ID_TYPE,
  IdType,
  PAYMENT_STATUS,
  Status,
  STATUS,
} from '../Models/OnlineCheckIn.Model'
import { ICheckinProperty } from '../Models/OnlineCheckInDTO.Model'
import { IStatusItem } from '../Components/StatusBar/StatusBar.Model'

export const statusToIndicatorMap = new Map<Status | undefined, string | null>([
  [STATUS.COMPLETE, 'SUKCES'],
  [STATUS.INCOMPLETE, 'PROBLEM'],
  [undefined, null],
])

export const titleToStatusMap = new Map<string, Status | undefined>([
  ['Sukces', STATUS.COMPLETE],
  ['Problemy', STATUS.INCOMPLETE],
  ['Wszystkie', undefined],
])

export const statusToStringBooleanMap = new Map<Status | undefined, 'true' | 'false' | undefined>([
  [STATUS.COMPLETE, 'true'],
  [STATUS.INCOMPLETE, 'false'],
])

export const statusIdToTitleMap = new Map<CheckInNavItems, string>([
  [CHECK_IN_NAV_ITEMS.ADDRESS_DATA, 'Dane adresowe'],
  [CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE, 'Akceptacja umowy'],
  [CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE, 'Akceptacja zgód'],
  [CHECK_IN_NAV_ITEMS.DEPOSIT, 'Opłata depozytu'],
  [CHECK_IN_NAV_ITEMS.DRIVING_LICENSE, 'Prawo jazdy'],
  [CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE, 'Akceptacja prawa jazdy'],
  [CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT, 'Dokument tożsamości'],
  [CHECK_IN_NAV_ITEMS.PERSONAL_DATA, 'Dane osobowe'],
  [CHECK_IN_NAV_ITEMS.TO_PAID, 'Opłata rezerwacji'],
])

export class StatusItemMap extends Map<CheckInNavItems, IStatusItem<CheckInNavItems>> {}

export const INIT_STATUS_ID_MAP = new StatusItemMap([
  [CHECK_IN_NAV_ITEMS.PERSONAL_DATA, { id: CHECK_IN_NAV_ITEMS.PERSONAL_DATA, status: STATUS.UNKNOWN, label: `1. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.PERSONAL_DATA)}`}],
  [CHECK_IN_NAV_ITEMS.ADDRESS_DATA, { id: CHECK_IN_NAV_ITEMS.ADDRESS_DATA, status: STATUS.UNKNOWN, label: `2. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.ADDRESS_DATA)}`}],
  [CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT, { id: CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT, status: STATUS.UNKNOWN, label: `3. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT)}`}],
  [CHECK_IN_NAV_ITEMS.DRIVING_LICENSE, { id: CHECK_IN_NAV_ITEMS.DRIVING_LICENSE, status: STATUS.UNKNOWN, label: `4. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE)}`}],
  [CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE, { id: CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE, status: STATUS.UNKNOWN, label: `5. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE)}`}],
  [CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE, { id: CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE, status: STATUS.UNKNOWN, label: `6. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE)}`}],
  [CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE, { id: CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE, status: STATUS.UNKNOWN, label: `7. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE)}`}],
  [CHECK_IN_NAV_ITEMS.TO_PAID, { id: CHECK_IN_NAV_ITEMS.TO_PAID, status: STATUS.UNKNOWN, label: `8. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.TO_PAID)}`}],
  [CHECK_IN_NAV_ITEMS.DEPOSIT, { id: CHECK_IN_NAV_ITEMS.DEPOSIT, status: STATUS.UNKNOWN, label: `9. ${statusIdToTitleMap.get(CHECK_IN_NAV_ITEMS.DEPOSIT)}`}]
])

const ID_TYPE_MAP = new Map([
  [ID_TYPE.UNKNOWN, null],
  [ID_TYPE.ID_CARD, 'Dowód osobisty'],
  [ID_TYPE.PASSPORT, 'Paszport'],
])

export type CheckInPropMapper = <Value>(value: Value, object: ICheckinProperty) => string

export class InputMap extends Map<string, [string, CheckInPropMapper?]>{}

export const PERSONAL_DATA_INPUT_MAP = new InputMap([
  ['firstName', ['Imię']],
  ['lastName', ['Nazwisko']],
  ['email', ['Email']],
  ['phone', ['Telefon']],
  ['erboId', ['ERBO ID']],
])

export const ADDRESS_DATA_INPUT_MAP = new InputMap([
  ['country', ['Kraj zamieszkania']],
  ['street', ['Ulica']],
  ['houseNumber', ['Numer lokalu']],
  ['city', ['Miejscowość']],
  ['postcode', ['Kod pocztowy']],
])

export const DEPOSIT_INPUT_MAP = new InputMap([
  ['depositStatus', ['Czy użytkownik dokonał pełnej opłaty za depozyt?', (status) => booleanMapper(status === PAYMENT_STATUS.PAID)]]
])

export const dateMapper = <T>(dateTime: T) => dateTime &&
  Intl.DateTimeFormat('pl-PL', { day: '2-digit', month: '2-digit', year: 'numeric' })
    .formatToParts(new Date(dateTime as string))
    .reduce((prev, curr) => curr.type === 'literal' ? prev + '-' : prev + curr.value, '')
export const booleanMapper = <T>(condition: T) => condition ? 'Tak' : 'Nie'

export const AGREEMENT_ACCEPTANCE_INPUT_MAP = new InputMap([
  ['agreementAccepted', ['Czy użytkownik zaakceptował umowę?', booleanMapper]],
])

export const IDENTITY_DOCUMENT_INPUT_MAP = new InputMap([
  ['idType', ['Dokument tożsamości', (idType) => ID_TYPE_MAP.get(idType as IdType) ?? '']],
  ['country', ['Kraj wydania dokumentu']],
  ['idNumber', ['Numer dowodu osobistego']],
  ['pesel', ['Numer PESEL']],
])

export const DRIVING_LICENSE_INPUT_MAP = new InputMap([
  ['country', ['Kraj wydania prawa jazdy']],
  ['number', ['Numer prawa jazdy']],
  ['expiryType', ['Rodzaj ważności prawa jazdy', (hasDate) => hasDate ? 'Terminowy' : 'Bezterminowy']],
  ['expiryDate', ['Data wygaśnięcia ważności prawa jazdy', dateMapper]],
  ['grantDate', ['Data nadania uprawnień do kierowania pojazdem', dateMapper]],
])

export const DRIVING_LICENSE_ACCEPTANCE_INPUT_MAP = new InputMap([
  ['status', ['Czy RMS zaakceptował prawo jazdy?', (status) => booleanMapper(status === DRIVING_LICENSE_STATUS.VERIFIED)]],
])

export const TO_PAID_INPUT_MAP = new InputMap([
  ['installmentStatus', ['Czy użytkownik dokonał pełnej opłaty za rezerwację?', (status) => booleanMapper(status === PAYMENT_STATUS.PAID)]],
])

export const FIELDS_ORDER_MAP = new Map([
  [CHECK_IN_NAV_ITEMS.PERSONAL_DATA, ['firstName', 'lastName','email','phone', 'erboId']],
  [CHECK_IN_NAV_ITEMS.ADDRESS_DATA, ['country', 'street','houseNumber', 'city', 'postcode']],
  [CHECK_IN_NAV_ITEMS.IDENTITY_DOCUMENT, ['idType', 'country', 'idNumber', 'pesel']],
  [CHECK_IN_NAV_ITEMS.DRIVING_LICENSE, ['country', 'number', 'expiryType', 'expiryDate', 'grantDate']],
  [CHECK_IN_NAV_ITEMS.DRIVING_LICENSE_ACCEPTANCE, ['status']],
  [CHECK_IN_NAV_ITEMS.AGREEMENT_ACCEPTANCE, ['agreementAccepted']],
  [CHECK_IN_NAV_ITEMS.CONSENT_ACCEPTANCE, undefined],
  [CHECK_IN_NAV_ITEMS.TO_PAID, ['installmentPaid', 'installmentStatus']],
  [CHECK_IN_NAV_ITEMS.DEPOSIT, ['depositStatus']]
])
