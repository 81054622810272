import React from "react";
import { Col } from "react-bootstrap";

interface IProps {
  handleSave?: React.MouseEventHandler<HTMLAnchorElement>;
}

const ReDirectButton = (props) => {
  return (
    <button
      style={{
        backgroundColor: "#283583",
        border: "1px solid #283583",
        color: "white",
        minWidth: "214px",
        minHeight: "60px",
        borderRadius: "30px",
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: "42px",
      }}
      onClick={(e) => {
        props.onClick?.(e as unknown as React.MouseEvent<HTMLAnchorElement>)
      }
      }
    > {props.children}</button>
  )
}

const SaveButton: React.FC<IProps> = (props) => {
  return (
    <>
      <Col
        xs="12"
        style={{
          marginBottom: "10px",
          padding: "0",
          textAlign: "center",
        }}
      >
        {/* @ts-ignore */}
        <ReDirectButton onClick={props.handleSave}>Zapisz</ReDirectButton>
      </Col>
    </>
  );
};

export default SaveButton;
