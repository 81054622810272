import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import SearchIcon from "../../../assets/searchIcon.svg";
import ClearIcon from "../../../assets/clear.svg";

import styles from "./Search.module.scss"
import { combineClasses } from '../../../external.module/CMSComponents.Module/Services/CssServices'
import InputBase from '@material-ui/core/InputBase';
import { IconButton } from '@material-ui/core'
import { InputBaseProps } from '@material-ui/core/InputBase/InputBase'

interface ISearch {
  placeholder?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onReset?: () => void
  classes?: {
    root?: string
    searchInput?: string
    endAdornment?: string
  }
  endAdornment?: InputBaseProps['endAdornment']
}

const Search: React.FC<ISearch> = ({
  placeholder,
  onChange,
  onReset,
  classes,
  endAdornment
}) => {
  const [value, setValue] = useState('')

  const handleChange: ISearch['onChange'] = (event) => {
    setValue(event.target.value)
    onChange?.(event)
  }

  const handleReset: ISearch['onReset'] = () => {
    setValue('')
    onReset?.()
  }

  return (
    <div className={combineClasses(styles['root'], classes?.root)}>
      <label htmlFor="search"><img src={SearchIcon} /></label>
      <InputBase
        id="search"
        onChange={handleChange}
        value={value}
        className={combineClasses(styles['search-input'], classes?.searchInput)}
        placeholder={placeholder}
        inputProps={{
          'aria-label': 'search',
          'autocomplete': 'off',
      }}
        endAdornment={
          <div className={combineClasses(styles['end-adornment'], classes?.endAdornment)}>
            {endAdornment}
            {value && (
              <IconButton onClick={handleReset} disableRipple>
                <img src={ClearIcon} />
              </IconButton>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Search;
