import React from "react";
import editIcon from "../../../../assets/edit.svg";
import RmsIcon from "../../../../assets/rms.svg";
import Eye from "../../../../assets/eye.svg";
import CrossedOutEye from "../../../../assets/crossedOutEye.svg";
import DottedButton from "../../../../assets/dottedButton.svg";
import { ITableCarsModel } from "../../Models/TableCars.Model";
import LinkComponent from "../../../../Common/Components/Link.Component";
import { AdditionalOptions } from "../../Components/Sections/AdditionalOption/AdditionalOption.Section";

interface IProps {
  newCarsData: Array<ITableCarsModel>;
  activeButton: string
}

const UsedCarsData: React.FC<IProps> = ({ newCarsData, activeButton }) => {

  const carContainerClassNameHandler = (activeButton: string) => {
    return activeButton === "W magazynie" ? "--inWarehouse" 
    : activeButton === "Opublikowane" || activeButton === 'Wszystkie'? "--published":"--other"
  }

  const displayVisibility = (status, visibility, activeButton) =>{
      if(status === 'PUBLISHED' && visibility){
        return <div className={"car-container_id"}>
        Widoczna
      </div>
      }
      if(status === 'PUBLISHED' && !visibility){
        return <div className={"car-container_hidden"}>
        Ukryta
      </div>
      }
      if(activeButton === 'Wszystkie'){
        return <div className={"car-container_hidden"}>
        Brak
      </div>
      }
        return null
  }

  return (
    <>
      {newCarsData?.map((element) => {
        const { erboId, rmsId, offerErboId, name, status, numberOfCars, carLogicWarehouse, visibility } = element;

        const addEditButton = () => {
          if (status == 'UNPUBLISHED') {
            return <LinkComponent
              icon={editIcon}
              path={`/usedCarsList/new/${erboId}`}
            />
          }
          if (status == 'PUBLISHED') {
            return <LinkComponent
              icon={editIcon}
              path={`/usedCarsList/edit/${erboId}`}
              data={{visibility: visibility}}
            />
          }
          if (status == 'RESERVED' && offerErboId) {
            return <LinkComponent
              icon={editIcon}
              path={`/usedCarsList/edit/${erboId}`}
            />
          }
        }

        const addCancelButton = () => {
          switch (status) {
            case 'PUBLISHED':
              return <AdditionalOptions 
                        title={'Ukryj pozycję'} 
                        buttonIcon ={DottedButton} 
                        miniIcon={CrossedOutEye}
                        />
            case 'UNPUBLISHED':
              return <AdditionalOptions 
                        title={'Aktywuj pozycję'} 
                        buttonIcon={DottedButton}
                        miniIcon={Eye} />   
            default:
              return null;  
          }
        }

        return (
          <div key={erboId} className={`car-container${carContainerClassNameHandler(activeButton)}`}>
            <div className="car-container_id">{erboId}</div>
            <div className="car-container_id">{rmsId}</div>
            {activeButton === "W magazynie" ? '' : <div className="car-container_id">{offerErboId ? offerErboId : 'Brak'}</div>}

            <div className="car-container_name">
              <h5 className="car-container_name-title">{name}</h5>
              <div className="car-container_name-number">
                {numberOfCars} szt
              </div>
            </div>
            <div className="car-container_id">{carLogicWarehouse ?? '-'}</div>
            <div
              className={
                /* sprawdzić też dla Active, ponieważ obecnie są 4 stany*/
                status === "PUBLISHED"
                  ? visibility 
                  ? "car-container_state--active" :"car-container_state--hidden"
                  : "car-container_state"
              }
            >
              {status}
            </div>
            {displayVisibility(status,visibility, activeButton)}
            <div className="car-container_icons">
              {addEditButton()
              }
              <LinkComponent
                icon={RmsIcon}
                path={`${process.env.REACT_APP_RMS_WWW_ADDRESS}/OfertaLACDTO/Edit/${rmsId}`}
                target
              />
              {/* Na razie ma nie być */}
              {/* {addCancelButton()} */}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default UsedCarsData;

