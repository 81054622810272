import makeStyles from '@material-ui/styles/makeStyles'

const palette = {colors: {success: '#04a479', warning: '#eb7a1a', error: '#be1e3e', info: '#2c61e9'}, backgrounds:{success: '#fcfcfd', warning: '#fef6ec', error: '#fde9e9', info: '#edf4fd'}}

export default makeStyles(() => ({
  root: {
    '& > .MuiPaper-root': {
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(10, 16, 30, 0.15)',
      borderLeftWidth: 8,
      borderLeftStyle: 'solid',
    },
  },
  success: {
    '& > .MuiPaper-root': {
      color: palette.colors.success,
      backgroundColor: palette.backgrounds.success,
    },
  },
  warning: {
    '& > .MuiPaper-root': {
      color: palette.colors.warning,
      backgroundColor: palette.backgrounds.warning,
    },
  },
  error: {
    '& > .MuiPaper-root': {
      color: palette.colors.error,
      backgroundColor: palette.backgrounds.error,
    },
  },
  info: {
    '& > .MuiPaper-root': {
      color: palette.colors.info,
      backgroundColor: palette.backgrounds.info,
    },
  },
  bottom: {
    bottom: 98,
  },
  top: {
    top: 98,
  },
  left: {
    left: 24,
  },
  right: {
    right: 24,
  },
  center: {}
}))
