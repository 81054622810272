import React from "react";

interface IProps {
  title?: string;
  titleClassess?: string;
  classess?: string;
}

const PlateComponent: React.FC<IProps> = (props) => {
  return (
    <div className={`plate-container ${props.classess}`}>
      <div className="plate-container__title">
        <h4 className="plate-container__title--text">{props.title}</h4>
      </div>
      {props.children}
    </div>
  );
};

export default PlateComponent;
