import React, { useState } from "react";
import { classGetter } from "../FormFiles.helpers";
import { IFormFile } from "./FormFile.models";
import { ReactComponent as DownloadIcon } from "../../../../assets/2download_doc.svg";
import LACOfferService, {
  LACOfferServiceEnum,
} from "../../../../Modules/LACOffers/Services/LACOffer.Service";

/**
 * Komponent renderuje widok pojedynczego pliku.
 *
 * Klasy CSS-owe:
 * - <b><i>file__item</i></b> - elementu przedstawiający jeden plik
 * - <b><i>file__item--only-one</i></b> - stan elementu pliku aktywny, tylko gdy jest możliwe wprowadzenie jednego pliku
 * - <b><i>file__item--overlay</i></b> - nakładka na element pliku aktywna, gdy parametr komponentu <i>overlayItemOnHover</i> jest włączony
 * - <b><i>file__item--hover</i></b> - symuluje najechanie myszką na element pliku na widoku mobilnym
 * - <b><i>file__item__delete</i></b> - znak usunięcia pliku dostępny, tylko gdy nie został przekazany parametr komponentu <i>deleteComponent</i>
 */
const FormFile = ({
  error,
  overlayOnHover,
  file,
  deleteComponent,
  deleteHandler,
  thumbnailProps,
  isSingle,
}: IFormFile): JSX.Element => {
  const [hover, setHover] = useState(false);

  if (!file) return <></>;

  return (
    <div
      className={classGetter([
        "file__item",
        isSingle && "file__item--only-one",
        overlayOnHover && "file__item--overlay",
        hover && "file__item--hover",
      ])}
      key={file.name}
      //   onTouchEnd={(event) => {
      //     event.preventDefault();
      //     // setHover((prevState) => !prevState);
      //   }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
        width={thumbnailProps?.width}
        height={thumbnailProps?.height}
      />
      {hover && (
        <span
          className="file__item__delete"
          onClick={() => deleteHandler(file, LACOfferServiceEnum.DELETE)}
          // onMouseEnter={(evt) => {
          //   // deleteHandler(file, evt);

          //   setHover((prevState) => !prevState);
          // }}
          //   onPointerUp={(evt) => {
          // deleteHandler(file, evt);

          // setHover((prevState) => !prevState);
          //   }}
        >
          &times;
        </span>
      )}
      {/* {deleteComponent ? (
        //@ts-ignore
        deleteComponent(deleteHandler, file)
      ) : (
        <span
          className="file__item__delete"
          onMouseEnter={(evt) => {
            // deleteHandler(file, evt);

            setHover((prevState) => !prevState);
          }}
          //   onPointerUp={(evt) => {
          // deleteHandler(file, evt);

          // setHover((prevState) => !prevState);
          //   }}
        >
          &times;
        </span>
      )} */}
      {error?.hasError && <span className="file__error">{error.msg}</span>}
    </div>
  );
};

export default FormFile;
