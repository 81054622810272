import React from "react";
import AdditionalParametersOfferForm from "../../Forms/AdditionalParametersOffer.Form";
import PlateComponent from "../../../../Common/Components/Plate.Component";

interface IProps {
  classess?: string;
}

const AddicionalParametersOfferSection: React.FC<IProps> = (props) => {

  return (
    <PlateComponent
      title="Dodatkowe parametry oferty"
      classess={props.classess ?? ""}
    >
      <AdditionalParametersOfferForm />
    </PlateComponent>
  );
};

export default AddicionalParametersOfferSection;
