import React from 'react';

const CloseIcon = ({ className }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.633"
    height="23.633"
    viewBox="0 0 23.633 23.633"
    className={className}
  >
    <g transform="translate(0 0)">
      <circle fill="none" cx="11.816" cy="11.816" r="11.816" />
    </g>
    <path fill="#fff" d="M18.235,8.595,17.164,7.523l-4.285,4.285L8.595,7.523,7.523,8.595l4.285,4.285L7.523,17.164l1.071,1.071,4.285-4.285,4.285,4.285,1.071-1.071-4.285-4.285Z" transform="translate(-1.063 -1.063)" />
  </svg>;

export default CloseIcon;
