import React from "react";
import BasicPriceVariableForm from "../../Forms/BasicPriceVariable.Form";
import RentalPeriodForm from "../../Forms/RentalPeriod.Form";
import NewCarsModel from "../../Models/NewCarsModel";
import PlateComponent from "../../../../Common/Components/Plate.Component";

interface IProps {
  car: NewCarsModel[];
}
const RentalPeriodSection: React.FC<IProps> = ({ car }) => {

  return (
    <>
      <PlateComponent title="Lista cenników z RMS (okres najmu i przejechane kilometry)">
        <p>{car[0]?.priceLists?.length} dostępnych cenników</p>
        <div className="price-list-wrapper">
          <RentalPeriodForm priceList={car[0]?.priceLists} />
        </div>
        <BasicPriceVariableForm />
      </PlateComponent>
    </>
  );
};

export default RentalPeriodSection;
