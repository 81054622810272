import { Actions, MessagePayload, Messages } from './Snackbar.Model'
import { ACTIONS, SNACKBAR_NAME, STATUS } from './Snackbar.Enum'
import { prepareTriggerEvent } from '../../Utility.Module/Hooks/useCustomEvent'

let id = 0

export const reducer = (messages: Messages = [], action: Actions): Messages => {
  switch (action.type) {
    case ACTIONS.ADD_MESSAGE: {
      return [
        {
          message: action.payload.message,
          id: id++,
          status: action.payload.status ?? STATUS.INFO,
        },
        ...messages,
      ]
    }
    case ACTIONS.REMOVE_MESSAGE: {
      const [, ...newMessages] = messages
      return newMessages
    }
    default:
      throw new Error('Unexpected action occurred.')
  }
}

/**
 * Metoda wywołuje snackbar o określonym statusie.
 * @param {MessagePayload} messagePayload - informacja o ładunku wiadomości
 * @param {string} messagePayload.message - wiadomość do pokazania w snackbarze
 * @param {STATUS} messagePayload.status - tryb w jakim pokazany zostanie snackbar
 */
export const showSnackbar = prepareTriggerEvent<MessagePayload>(SNACKBAR_NAME)

/**
 * Metoda wywołuje snackbar w trybie powodzenia.
 * @param {string} message - wiadomość do pokazania w snackbarze
 */
export const showSuccessSnackbar = (message: string) => {
  prepareTriggerEvent<MessagePayload>(SNACKBAR_NAME)({ message, status: STATUS.SUCCESS })
}

/**
 * Metoda wywołuje snackbar w trybie błędu.
 * @param {string} message - wiadomość do pokazania w snackbarze
 */
export const showErrorSnackbar = (message: string) => {
  prepareTriggerEvent<MessagePayload>(SNACKBAR_NAME)({ message, status: STATUS.ERROR })
}

/**
 * Metoda wywołuje snackbar w trybie informującym.
 * @param {string} message - wiadomość do pokazania w snackbarze
 */
export const showInfoSnackbar = (message: string) => {
  prepareTriggerEvent<MessagePayload>(SNACKBAR_NAME)({ message, status: STATUS.INFO })
}

/**
 * Metoda wywołuje snackbar w trybie ostrzegającym.
 * @param {string} message - wiadomość do pokazania w snackbarze
 */
export const showWarningSnackbar = (message: string) => {
  prepareTriggerEvent<MessagePayload>(SNACKBAR_NAME)({ message, status: STATUS.WARNING })
}
