
import { Errors, IError, IFormFiles, InferredFile } from './FormFiles.models';

export const createError = (msg: IError['msg']): IError => ({ hasError: true, msg });
export const prepareErrors = (
    uploadedFiles: IFormFiles['files'],
    hasErrorPredicate: (file: File) => boolean,
    errorMsg: string,
): Errors => uploadedFiles?.map((file) => ({
    hasError: hasErrorPredicate(file),
    fileName: file.name,
    msg: errorMsg,
}))??[];
export const classGetter = (classes: Array<string | false | undefined>) => classes.filter((cls) => cls).join(' ');
//@ts-ignore
export const includes = (arr: Array<string>, search: string): boolean => arr.reduce<boolean>(
    (include, element) => (element.includes('*') && new RegExp(`^${element.replace('/*', '')}`).test(search)) || include,
    false,
);
