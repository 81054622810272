import { showSuccessSnackbar } from "../../../external.module/CMSComponents.Module/Snackbar";
import { ImageCompressionService } from "../../ImageCompression/Services/ImageCompression.Service";
import LACOffersConnector from "../../UsedCars/Connectors/LACOffers.Connector";
import UsedCarsMapper from "../../UsedCars/Mappers/UsedCars.Mapper";
import NewCarsModel from "../../UsedCars/Models/NewCarsModel";
import LACOfferConnector from "../Connectors/LACOffer.Connector";
import LACOfferRepository from "../Repositories/LACOffer.Repository";


export enum LACOfferServiceEnum {
  DELETE="DELETE",
  ADD = "ADD"
}
export default class LACOfferService {
  public static set_carGroupId(_carGrooupId: number) {
    LACOfferRepository.carGroupId = _carGrooupId;
  }

  public static async fetch_offerByStatus(query: string) {
    const response = await LACOffersConnector.fetch_carsGroupByStatus(query);
    if (!response) {
      return { newResults: [], totalCount: 0 }
    }
    const { totalCount } = response.carGroupDtoPaginatedList;
    const newResults = UsedCarsMapper.map_newCarsFromModel(response);
    return { newResults, totalCount };
  }

  public static set_priceElementToPriceList(_idPriceListElement: number) {
    !LACOfferRepository.priceListIds &&
      (LACOfferRepository.priceListIds = []);
    if (!LACOfferRepository.priceListIds.includes(_idPriceListElement)) {
      LACOfferRepository.priceListIds?.push(_idPriceListElement);
    }
  }

  public static del_priceElementToPriceList(_idPriceListElement: number) {
    //TODO: Zasnanowić się czy można zrobić to sprytniej?
    if (LACOfferRepository.priceListIds?.includes(_idPriceListElement)) {
      const index = LACOfferRepository.priceListIds?.findIndex((e) => {
        if (e === _idPriceListElement) {
          return _idPriceListElement;
        }
      });
      LACOfferRepository.priceListIds.splice(index, 1);
    }
  }

  public static set_allPriceListActive(_carData: NewCarsModel[] | null) {
    !LACOfferRepository.priceListIds &&
      (LACOfferRepository.priceListIds = []);
    if (_carData)
      {_carData[0].priceLists?.forEach((priceList)=>{
        LACOfferRepository.priceListIds?.push(priceList.id)
      })}
  }

  public static toggle_priceElementToPriceList(_idPriceListElement: number) {
    if (!LACOfferRepository.priceListIds?.includes(_idPriceListElement)) {
      this.set_priceElementToPriceList(_idPriceListElement);
    } else {
      this.del_priceElementToPriceList(_idPriceListElement);
    }
  }

  public static set_mainPriceListId(
    _idPriceListElement: number | undefined
  ) {
    LACOfferRepository.mainPriceListId = _idPriceListElement;
  }

  public static async get_LACOffer(_id:string | number) {
    const response = await LACOfferConnector.GET_offer(_id)
    const data = response.cmsOfferDetailsDto
    if (response){
      this.set_lacOfferAsteriskInformation(data.asteriskInformation)
      this.set_lacOfferDescription(data.description)
      this.set_lacOfferTypeText(data.type)
      this.set_basicPrice(data.lacBasicPriceDto.netPrice, data.lacBasicPriceDto.grossPrice)
  }
    return data
  }

  public static edit_LACOffer(id) {
    if (
      LACOfferRepository.priceListIds &&
      LACOfferRepository.lacBasicPriceDto?.netPrice &&
      LACOfferRepository.lacBasicPriceDto?.grossPrice
    ) {
      const data = {
        mainPriceListId: LACOfferRepository.mainPriceListId ? LACOfferRepository.mainPriceListId : undefined,
        priceListIds: LACOfferRepository.priceListIds.length ? LACOfferRepository.priceListIds : undefined,
        asteriskInformation: LACOfferRepository.asteriskInformation ? LACOfferRepository.asteriskInformation : undefined,
        description: LACOfferRepository.description ? LACOfferRepository.description : undefined,
        type: LACOfferRepository.typeText ? LACOfferRepository.typeText : undefined,
        modelEquipmentDetail: LACOfferRepository.modelEquipmentDetail ? LACOfferRepository.modelEquipmentDetail : undefined,
        lacBasicPriceDto: LACOfferRepository.lacBasicPriceDto
      };
      LACOfferConnector.EDIT_offer( id , data);
      showSuccessSnackbar('udało się')
    }
  }

  public static async edit_Visibility(id, visibility){
    const result = await LACOfferConnector.EDIT_visibility(id, visibility)
    result.status.toString().startsWith('2') && showSuccessSnackbar('Zmieniono widoczność pojazdu')
  }

  public static post_LACOffer() {
    if (
      LACOfferRepository.priceListIds &&
      LACOfferRepository.lacBasicPriceDto?.netPrice &&
      LACOfferRepository.lacBasicPriceDto?.grossPrice
    ) {
      const data = {
        carGroupId: LACOfferRepository.carGroupId ? LACOfferRepository.carGroupId: undefined,
        mainPriceListId: LACOfferRepository.mainPriceListId ? LACOfferRepository.mainPriceListId : undefined,
        priceListIds: LACOfferRepository.priceListIds.length ? LACOfferRepository.priceListIds : undefined,
        asteriskInformation: LACOfferRepository.asteriskInformation ? LACOfferRepository.asteriskInformation : undefined,
        description: LACOfferRepository.description ? LACOfferRepository.description : undefined,
        type: LACOfferRepository.typeText ? LACOfferRepository.typeText : undefined,
        modelEquipmentDetail: LACOfferRepository.modelEquipmentDetail ? LACOfferRepository.modelEquipmentDetail : undefined,
        lacBasicPriceDto: LACOfferRepository.lacBasicPriceDto
      };
      LACOfferConnector.POST_offer(data);
      showSuccessSnackbar('udało się')
    }
  }

  public static async set_mainImageWithCompression(_mainImage) {
    const CI: File = await ImageCompressionService.CI(_mainImage[0])
    _mainImage = CI as File
    LACOfferRepository.mainImage = [_mainImage];
    console.log(LACOfferRepository.mainImage)
  }
public static clear_mainImageRepo() {
  return LACOfferRepository.mainImage =[]
}
  public static async set_mainImageNoCompression(mainImage) {
       return LACOfferRepository.mainImage = [mainImage];
  }

  public static get_mainImage() {
      return LACOfferRepository.mainImage
  }

  private static CompressImages(_images: Array<File>): Promise<Array<File>> {
    let promiseArray: Array<Promise<any>> = []
    _images.forEach((image) => {
      promiseArray.push(ImageCompressionService.CI(image))
    })
    return Promise.all(promiseArray).then((result => {
      return result
    }))
  }

  public static async set_additionalImagesWithCompression(images: File[] | File, method?:LACOfferServiceEnum) {

    images = Array.isArray(images) ? images : new Array(images)
    if(method !==  LACOfferServiceEnum.DELETE) {
      const compressImages = await LACOfferService.CompressImages(images)
      images = compressImages
    }

    if (Array.isArray(images)) {
      LACOfferRepository.additionalImages = images
      return
    }
    const temp = LACOfferRepository.additionalImages ?? [] as File[]
    LACOfferRepository.additionalImages = [...temp, images];
    console.log(LACOfferRepository.additionalImages)
  }

  public static async set_additionalImagesNoCompression(image: File) {
    const temp = LACOfferRepository.additionalImages ?? [] as File[]
    LACOfferRepository.additionalImages = [...temp, image];
  }

  public static get_additionalImages() {
    return LACOfferRepository.additionalImages
}

  public static post_LacOfferImage(
    _carGroupId: number,
    _images: File[],
    isMain: boolean
  ) {
    const createArrayImages = Object.values(_images);
    createArrayImages.forEach((element) => {
      const dataForm: FormData = new FormData();
      dataForm.append("filename", element.name);
      dataForm.append("isMain", isMain.toString());
      dataForm.append("file", element);
      LACOffersConnector.POST_carGroupImage(_carGroupId, dataForm);
    });
  }

  public static getErboCar(erboId: string) {
    return LACOffersConnector.GET_SingleCarFromErbo(erboId);
  }


  public static async get_carGroupMainImage(_url: string, _mainImageId: number){
    try {
    const mainImageBlob = await LACOffersConnector.GET_carGroupMainImage(_url)
    if (mainImageBlob){
    const mainImage = new File([mainImageBlob], `main${_mainImageId}.webp`, {type: mainImageBlob.type})
    this.set_mainImageNoCompression(mainImage)
    }
  }
    catch (error) {console.log(error)}
  }

  public static async del_LacOfferAdditionalImages(groupID: number, _listOfImagesIdsToDelete: number[]){
    try{
      const listOfImagesIdsToDelete = {listOfImageIdsToDelete: _listOfImagesIdsToDelete}
      const response= await LACOffersConnector.DEL_carGroupAdditionalImages(groupID, listOfImagesIdsToDelete)
      return response.ok
    }
    catch (error){
    return false
    }
  }

  public static async del_LacOfferMainImage(groupID: number, mainImageId: number){
    try{
    await LACOffersConnector.DEL_carGroupMainImage(groupID, mainImageId)
    }
    catch (error){
    console.log(error);
    }
  }

  public static async get_carGroupAdditionalImages(carGroupImageUrls: string[]){
    carGroupImageUrls.forEach(async imageUrl => { const additionalImageBlob = await LACOffersConnector.GET_carGroupAdditionalImage(imageUrl)
      const fileName = imageUrl.split('/').reverse()[0].split('.')[0]
      this.set_additionalImagesNoCompression(new File([additionalImageBlob], fileName, {type: additionalImageBlob.type}))
    })
  }

  // public static async get_carGroupAdditionalImagesByIds(carGroupImageIds: string[] | number[], id: string | number){
  //   carGroupImageIds.forEach(async imageId => { const additionalImageBlob = await LACOffersConnector.GET_carGroupAdditionalImageById(String(imageId), String(id))
  //     this.set_additionalImages(new File([additionalImageBlob], imageId, {type: additionalImageBlob.type}))
  //   })
  // }

  // public static set_basicPriceConstant(_basicPriceConstant: number): void {
  //   if (_basicPriceConstant === undefined || null || '') {
  //     _basicPriceConstant = LACOfferConst.basicPriceConstant
  //   }
  //   LACOfferRepository.lacBasicPriceDto.netPrice = _basicPriceConstant as number
  //   LACOfferRepository.lacBasicPriceDto.grossPrice = ((1 + LACOfferConst.vat) * _basicPriceConstant) as number
  // }

  public static async getLACOfferIDfromGroupID(groupID: string | number){
    const data = await LACOfferConnector.GET_offerID(groupID)
    this.set_OfferID(data.offerId)
    return data
  }

  public static set_OfferID(_offerID: number) {
    LACOfferRepository.OfferID = _offerID
  }

  public static set_basicPrice(_basicPriceNet, _basicPriceGross){
    LACOfferRepository.lacBasicPriceDto = {
      netPrice:_basicPriceNet,
      grossPrice: _basicPriceGross}
  }

  public static get_lacBasicNetPrice(){
      return LACOfferRepository.lacBasicPriceDto?.netPrice
  }

  public static get_lacBasicGrossPrice(){
    return LACOfferRepository.lacBasicPriceDto?.grossPrice
  }

  public static set_lacOfferAsteriskInformation(_asteriskInformation: string) {
  LACOfferRepository.asteriskInformation = _asteriskInformation;
  }

  public static get_lacOfferAsteriskInformation(){
  return LACOfferRepository.asteriskInformation
  }

  public static set_lacOfferDescription(_description: string) {
  LACOfferRepository.description = _description;
  }

  public static get_lacOfferDescription(){
  return LACOfferRepository.description
  }

  public static set_lacOfferTypeText(_typeText) {
  LACOfferRepository.typeText = _typeText;
  }

  public static get_lacOfferTypeText(){
  return LACOfferRepository.typeText
  }

  public static fetchLacOfferByVisibility() {
    return
  }
}
