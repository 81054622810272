import React, { FC } from 'react'
import { STATUS } from '../../Models/OnlineCheckIn.Model'
import type { Status as TStatus } from '../../Models/OnlineCheckIn.Model'
import styles from './Status.module.scss'
import TickIcon from '../TickIcon.Component'
import CloseIcon from '../CloseIcon.Component'
import { combineClasses } from '../../../../external.module/CMSComponents.Module/Services/CssServices'

interface IStatus {
  status?: TStatus
  onClick?: () => void
  className?: string
  variant?: 'right' | 'center' | 'left'
}

const isRight = (variant: IStatus['variant']) => variant === 'right'
const isCenter = (variant: IStatus['variant']) => variant === 'center'

const Status: FC<IStatus> = ({
  status,
  onClick ,
  className,
  children,
  variant = 'left'
}) => (
  <div
    onClick={onClick}
    className={combineClasses(
      className,
      styles['root'],
      isRight(variant) && styles['root--right'],
      isCenter(variant) && styles['root--center'],
    )}
  >
    {status !== STATUS.INCOMPLETE
      ? <TickIcon className={styles[`status-indicator--${(status ?? STATUS.UNKNOWN).toLowerCase()}`]} />
      : <CloseIcon className={styles[`status-indicator--${status.toLowerCase()}`]} />}
    {children}
  </div>
)

export default Status
