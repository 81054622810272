import React, { SetStateAction, Dispatch } from "react";
import LACOfferService, {
  LACOfferServiceEnum,
} from "../../LACOffers/Services/LACOffer.Service";
import FormFiles from "../../../Forms/FormsElements/FormFiles/FormFiles";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { IFormFiles } from "../../../Forms/FormsElements/FormFiles/FormFiles.models";
import PlateComponent from "../../../Common/Components/Plate.Component";

interface IProps {
  setAdditionalImagesIdsToDelete: Dispatch<SetStateAction<number[]>>;
  setMainImageToDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputForms = observer(
  ({ setAdditionalImagesIdsToDelete, setMainImageToDelete }: IProps) => {
    const setImageDataToPostOrDelete: IFormFiles["onFilesUpdate"] = async (
      files,
      eventOrFile,
      method?
    ) => {
      if (eventOrFile instanceof File) {
        setAdditionalImagesIdsToDelete((prevValue) => [
          ...prevValue,
          Number(eventOrFile.name),
        ]);
      }
      await LACOfferService.set_additionalImagesWithCompression(files, method);
    };

    const setDelMainImage: IFormFiles["onFilesUpdate"] = (
      files,
      eventOrFile,
      method?
    ) => {
      if (eventOrFile instanceof File) {
        setMainImageToDelete(true);
      }
      if (method === LACOfferServiceEnum.DELETE) {
        return LACOfferService.clear_mainImageRepo();
      }
      LACOfferService.set_mainImageWithCompression(files);
    };

    return (
      <>
        <PlateComponent title="Zdjęcia oferty">
          <div className="input-files">
            <div className="input-files__container">
              <FormFiles
                description="Zdjęcie główne"
                id="1"
                name="mainImg"
                files={toJS(LACOfferService.get_mainImage())}
                onFilesUpdate={setDelMainImage}
              />
            </div>
            <div className="input-files__container">
              <FormFiles
                description="Zdjęcia dodatkowe"
                id="2"
                name="additionalImg"
                files={toJS(LACOfferService.get_additionalImages())}
                onFilesUpdate={setImageDataToPostOrDelete}
                multiple
              />
            </div>
          </div>
        </PlateComponent>
      </>
    );
  }
);

export default InputForms;
